import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes, css } from "styled-components";
import { useTranslation } from "react-i18next";

const flyIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(50px); 
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Div = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 3.5rem;
  @media (max-width: 1024px) {
    height: 36.5%;
    padding-top: 7rem;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 7%;
  @media (max-width: 1024px) {
    padding-left: 0;
  }
`;

const Subject = styled.p`
  font-family: "Pretendard-SemiBold";
  font-size: 2.5rem;
  padding-bottom: 3.5rem;
  padding-left: 2.5rem;
  opacity: ${(props) => (props.$isVisible ? 1 : 0)};
  animation: ${(props) =>
    props.$isVisible
      ? css`
          ${flyIn} 3s ease-out
        `
      : "none"};
  @media (max-width: 480px) {
    padding-left: 2.5rem;
    font-size: 2rem;
  }
`;

const SelectBox = styled.div`
  width: 20rem;
  height: 3.5rem;
  border-radius: 2rem;
  font-family: "Pretendard-SemiBold";
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  gap: 0.3rem;
  margin-left: 2rem;
  opacity: ${(props) => (props.$isVisible ? 1 : 0)};
  animation: ${(props) =>
    props.$isVisible
      ? css`
          ${flyIn} 2s ease-out
        `
      : "none"};
  @media (max-width: 480px) {
    width: 14rem;
    height: 2.5rem;
  }
`;

const SelectInnerBox = styled.div`
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  border-radius: 2rem;
  background-color: ${(props) => (props.$isSelected ? "black" : "white")};
  color: ${(props) => (props.$isSelected ? "white" : "black")};
  @media (max-width: 480px) {
    height: 2rem;
    font-size: 0.75rem;
  }
`;

const ContentBox = styled.div`
  display: flex;
  width: 100%;
  transition: transform 0.5s ease;
  overflow: hidden;
  position: relative;
  @media (max-width: 1024px) {
    flex-direction: row;
    scroll-snap-type: x mandatory;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const ContentWrapper = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const ImageBox = styled.div`
  width: 100%;
  height: 25rem;
  margin-bottom: 3rem;
  opacity: ${(props) => (props.$isVisible ? 1 : 0)};
  animation: ${(props) =>
    props.$isVisible
      ? css`
          ${flyIn} 3s ease-out
        `
      : "none"};
`;

const ContentImage = styled.div`
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 25rem;
`;

const ContentInnerBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1.2rem;
  cursor: pointer;
  align-items: center;
  opacity: ${(props) => (props.$isSelected ? 1 : 0.5)};
  transition: opacity 0.3s ease;
  width: 60%;
  height: 100%;
  padding: 2rem;
  z-index: 1;
  animation: ${(props) =>
    props.$isVisible
      ? css`
          ${flyIn} 3s ease-out
        `
      : "none"};
  @media (max-width: 1024px) {
    flex: 0 0 100%;
    scroll-snap-align: start;
  }
`;

const InnerSubject = styled.p`
  font-family: "Pretendard-Medium";
  color: #a64424;
  margin-bottom: 1rem;
  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

const InnerContent = styled.p`
  font-family: "Pretendard-Regular";
  color: black;
  margin-bottom: 1rem;
  @media (max-width: 480px) {
    font-size: 0.8rem;
  }
`;

const ButtonBox = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
`;

const InnerMoreButton = styled.button`
  width: 5.5rem;
  height: 2rem;
  border: none;
  color: white;
  background-color: #2369b4;
  border-radius: 2rem;
  padding: 0.5rem;
  transition: all 0.1s linear;
  cursor: pointer;
  font-family: "Pretendard-Regular";
  &:hover {
    transform: scale(1.05);
  }
`;

const ContactButton = styled.button`
  width: 5.5rem;
  height: 2rem;
  border: none;
  color: #2369b4;
  background-color: white;
  border-radius: 2rem;
  padding: 0.5rem;
  transition: all 0.1s linear;
  cursor: pointer;
  font-family: "Pretendard-Regular";
  &:hover {
    transform: scale(1.05);
  }
`;

const ContentData = {
  medical: {
    main: "Summary.medicalMain",
    sub: "Summary.medicalSub",
    image: "/assets/images/medical.png",
    file: "Return Plus IR Document.docx",
  },
  hyperCar: {
    main: "Summary.hyperCarMain",
    sub: "Summary.hyperCarSub",
    image: "/assets/images/car.png",
    file: "Return Plus IR Document.docx",
  },
  drawing: {
    main: "Summary.drawingMain",
    sub: "Summary.drawingSub",
    image: "/assets/images/gallery.png",
    file: "Return Plus IR Document.docx",
  },
};

function Summary({ onScrollToSection }) {
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const items = Object.keys(ContentData);
  const contentBoxRef = useRef(null);
  const prevIndexRef = useRef(selectedIndex);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.3 }
    );

    const currentRef = contentBoxRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setSelectedIndex((prevIndex) => {
        if (prevIndex === items.length - 1) {
          scrollToSelectedItem(0);
          return 0;
        } else {
          const nextIndex = (prevIndex + 1) % items.length;
          scrollToSelectedItem(nextIndex);
          return nextIndex;
        }
      });
    }, 3000);

    return () => clearInterval(intervalId);
  }, [items.length]);
  const handleScroll = (event) => {
    const { scrollLeft, clientWidth } = event.target;
    const itemWidth = clientWidth;
    const index = Math.round(scrollLeft / itemWidth);
    if (index >= 0 && index < items.length && index !== prevIndexRef.current) {
      setSelectedIndex(index);
      prevIndexRef.current = index;
    }
  };

  const handleFileDownload = (file) => {
    try {
      const fileUrl = `${process.env.PUBLIC_URL}/assets/${file}`;
      const fileName = file.split(".")[0];
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = `${fileName} IR 자료.docx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      alert("지원되지 않는 브라우저입니다. Chrome으로 접속해주세요.");
    }
  };

  const scrollToSelectedItem = (index) => {
    if (contentBoxRef.current) {
      const itemWidth = contentBoxRef.current.clientWidth;
      contentBoxRef.current.scrollTo({
        left: itemWidth * index,
        behavior: "auto",
      });
    }
  };

  return (
    <Div>
      <Wrapper>
        <Subject $isVisible={isVisible}>{t("Summary.subject")}</Subject>
        <SelectBox $isVisible={isVisible}>
          {items.map((key, index) => (
            <SelectInnerBox key={key} $isSelected={selectedIndex === index}>
              {t(`Summary.${key}`)}
            </SelectInnerBox>
          ))}
        </SelectBox>
        <ContentWrapper>
          <ContentBox ref={contentBoxRef} onScroll={handleScroll}>
            {items.map((key) => (
              <ContentInnerBox
                key={key}
                $isSelected={selectedIndex === items.indexOf(key)}
                $isVisible={isVisible}
                onClick={() => setSelectedIndex(items.indexOf(key))}
              >
                <ImageBox $isVisible={isVisible}>
                  <ContentImage src={ContentData[key].image} />
                </ImageBox>
                <InnerSubject>{t(ContentData[key].main)}</InnerSubject>
                <InnerContent>{t(ContentData[key].sub)}</InnerContent>
                <ButtonBox>
                  <InnerMoreButton
                    onClick={() => handleFileDownload(ContentData[key].file)}
                  >
                    {t("Summary.moreInfo")}
                  </InnerMoreButton>
                  <ContactButton onClick={() => onScrollToSection("research")}>
                    {t("Summary.contact")}
                  </ContactButton>
                </ButtonBox>
              </ContentInnerBox>
            ))}
          </ContentBox>
        </ContentWrapper>
      </Wrapper>
    </Div>
  );
}

export default Summary;
