import React, { useEffect, useCallback } from "react";
import styled from "styled-components";
import EmailForm from "../EmailForm/EmailForm";
import { useTranslation } from "react-i18next"; // i18n import

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  opacity: ${(props) => (props.$isOpen ? 1 : 0)};
  transition: opacity 0.3s ease;
  pointer-events: ${(props) => (props.$isOpen ? "auto" : "none")};
`;

const Div = styled.div`
  position: fixed;
  top: 0;
  right: -100rem;
  width: 40rem;
  height: 100%;
  background-color: white;
  color: black;
  transition: right 0.3s ease;
  padding: 3rem 3rem 3rem 3rem;
  z-index: 101;
  overflow-y: auto;
  &.open {
    right: 0;
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  display: block;
`;

const InfoBox = styled.div`
  width: 100%;
  height: 2rem;
  font-family: "Pretendard-Medium";
  font-size: 1.3rem;
  line-height: 1.5rem;
  display: flex;
  justify-content: space-between;
`;

const CloseButton = styled.button`
  font-family: "Pretendard-Light";
  font-size: 1rem;
  width: 5rem;
  height: 2rem;
  margin-bottom: 1rem;
  border: none;
  background: none;
  float: right;
  cursor: pointer;
  color: #050303;
  text-decoration: none;
  @media (min-width: 1024px) {
    display: none;
  }
`;

const Subject = styled.p`
  font-family: "Pretendard-Regular";
  font-size: 1rem;
  line-height: 1.2rem;
  height: 2rem;
`;

function Sidebar({ isOpen, setIsOpen }) {
  const { t } = useTranslation();
  const closeSidebar = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && event.target.closest(".OverLay")) {
        closeSidebar();
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen, closeSidebar]);

  const handleLinkClick = (e) => {
    e.preventDefault();
    closeSidebar();
  };

  return (
    <>
      <Overlay className="OverLay" $isOpen={isOpen} onClick={closeSidebar} />
      <Div className={`SidebarBox ${isOpen ? "open" : undefined}`}>
        <Wrapper>
          <CloseButton onClick={handleLinkClick}>
            {t("Sidebar.close")}X
          </CloseButton>
          <InfoBox>
            <p>{t("Sidebar.downloadCompanyProfile")}</p>
          </InfoBox>
          <Subject>{t("Sidebar.enterDetails")}</Subject>
          <EmailForm />
        </Wrapper>
      </Div>
    </>
  );
}

export default Sidebar;
