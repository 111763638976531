import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const IRButtonText = styled.span`
  transform: ${(props) =>
    props.$t === "ko" ? "translateX(1.7rem)" : "translateX(1rem)"};
  color: #fff;
  font-family: "Pretendard-Medium";
  font-size: 1.2rem;
  transition: all 0.3s;
  @media (max-width: 480px) {
    font-size: 1rem;
    transform: ${(props) =>
      props.$t === "ko" ? "translateX(1rem)" : "translateX(0.8rem)"};
  }
`;
const IRButtonIcon = styled.span`
  transition: all 0.3s;
  position: absolute;
  transform: translateX(4rem);
  height: 100%;
  width: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const IRButtonSvg = styled.svg`
  width: 1.2rem;
  fill: black;
`;

const IRButton = styled.button`
  margin-top: 1rem;
  position: relative;
  width: 10rem;
  height: 2.3rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid white;
  background: none;
  overflow: hidden;
  transition: all 0.3s;
  &:hover {
    background: white;
    > ${IRButtonText} {
      color: transparent;
    }
    > ${IRButtonIcon} {
      width: 8rem;
      transform: translateX(0.5rem);
    }
  }
  &.active {
    border: 1px solid white;
    > ${IRButtonIcon} {
      background-color: white;
    }
  }
  @media (max-width: 480px) {
    width: 8rem;
    height: 2rem;
    &:hover {
      background: white;
      > ${IRButtonText} {
        color: transparent;
      }
      > ${IRButtonIcon} {
        width: 6rem;
        transform: translateX(0.2rem);
      }
    }
  }
  @media (min-width: 481px) and (max-width: 768px) {
  }
  @media (min-width: 769px) and (max-width: 1024px) {
  }
`;

function IRDownload({ setIsOpen }) {
  const { t, i18n } = useTranslation();
  return (
    <IRButton onClick={() => setIsOpen(true)}>
      <IRButtonText $t={i18n.language}>
        {t("IRDownload.buttonText")}
      </IRButtonText>{" "}
      <IRButtonIcon>
        <IRButtonSvg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 35 35"
          id="bdd05811-e15d-428c-bb53-8661459f9307"
          data-name="Layer 2"
          className="svg"
        >
          <path d="M17.5,22.131a1.249,1.249,0,0,1-1.25-1.25V2.187a1.25,1.25,0,0,1,2.5,0V20.881A1.25,1.25,0,0,1,17.5,22.131Z"></path>
          <path d="M17.5,22.693a3.189,3.189,0,0,1-2.262-.936L8.487,15.006a1.249,1.249,0,0,1,1.767-1.767l6.751,6.751a.7.7,0,0,0,.99,0l6.751-6.751a1.25,1.25,0,0,1,1.768,1.767l-6.752,6.751A3.191,3.191,0,0,1,17.5,22.693Z"></path>
          <path d="M31.436,34.063H3.564A3.318,3.318,0,0,1,.25,30.749V22.011a1.25,1.25,0,0,1,2.5,0v8.738a.815.815,0,0,0,.814.814H31.436a.815.815,0,0,0,.814-.814V22.011a1.25,1.25,0,1,1,2.5,0v8.738A3.318,3.318,0,0,1,31.436,34.063Z"></path>
        </IRButtonSvg>
      </IRButtonIcon>
    </IRButton>
  );
}

export default IRDownload;
