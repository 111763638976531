import styled from "styled-components";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import PostDetailSlider from "../../components/Slider/PostDetailSlider";
import { postListData } from "../../api/staticData";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
`;
const MainImgBox = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;
const MainImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const MainTextBox = styled.div`
  position: absolute;
  bottom: 10vh;
  left: 7vw;
  color: #000;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  @media screen and (max-width: 1024px) {
    width: 60vw;
  }
  @media screen and (max-width: 430px) {
    gap: 0.4rem;
    width: 90vw;
  }
`;

const MainTag = styled.span`
  font-size: 1.625rem;
  @media screen and (max-width: 430px) {
    font-size: 1rem;
  }
`;
const MainTitle = styled.span`
  font-size: 3.125rem;
  white-space: pre-wrap;
  font-weight: 700;
  line-height: 1.2;
  word-break: keep-all;

  @media screen and (max-width: 430px) {
    font-size: 2.125rem;
  }
`;

const MainDate = styled.span`
  color: #91004e;
  font-weight: 700;
  font-size: 1.125rem;
  letter-spacing: -0.4px;
  margin-top: 2rem;
  @media screen and (max-width: 430px) {
    font-size: 1rem;
  }
`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1300px;
  margin: 0 auto;
  margin-bottom: 13.5rem;
  @media screen and (max-width: 1024px) {
    margin-bottom: 5.188rem;
    padding: 0 3rem;
    max-width: 100%;
  }
  @media screen and (max-width: 430px) {
    padding: 0 1.563rem;
  }
`;

const Content = styled.div`
  margin-top: 13.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @media screen and (max-width: 1024px) {
    gap: 1.5rem;
    margin-top: 5.188rem;
  }
`;

const Question = styled.span`
  color: #a64424;
  font-weight: 600;
  border-left: 4px solid #a64424;
  padding: 0.3rem 0 0.3rem 1rem;
  font-size: 1.8rem;
  @media screen and (max-width: 1024px) {
    font-size: 1.4rem;
  }
  @media screen and (max-width: 430px) {
    font-size: 1.3rem;
  }
`;

const Answer = styled.span`
  font-size: 1.6rem;
  line-height: 1.6;
  word-break: keep-all;
  white-space: pre-wrap;
  @media screen and (max-width: 1024px) {
    font-size: 1.2rem;
  }
  @media screen and (max-width: 430px) {
    font-size: 1.1rem;
  }
`;

const TagList = styled.ul`
  width: 100%;
  display: flex;
  gap: 0.3rem;
  margin: 0 auto;
  margin-bottom: 3rem;
  max-width: 1240px;
  @media screen and (max-width: 1024px) {
    margin-bottom: 3rem;
    padding: 0 3rem;
    max-width: 100%;
  }
  @media screen and (max-width: 430px) {
    padding: 0 1.563rem;
    margin-bottom: 1.5rem;
  }
`;

const TagItem = styled.li`
  font-size: 1.1rem;
  color: #b2b2b2;
  border: 1px solid #b2b2b2;
  padding: 0.2rem 0.4rem;
  border-radius: 0.5rem;
`;

const PaginationBox = styled.div`
  border-top: 1px solid #000;
  padding: 3rem 8rem;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1024px) {
    margin-bottom: 3rem;
    padding: 1rem 1.5rem;
    max-width: 100%;
  }
  @media screen and (max-width: 430px) {
    padding: 0.5rem 0;
  }
`;

const PaginationBtn = styled.button`
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: #fff;
  border: none;
  color: #8e8e8e;
  svg {
    width: 5rem;
    height: 5rem;
    fill: #8e8e8e;
  }
  span {
    font-weight: 300;
    font-size: 2.5rem;
  }
  @media screen and (max-width: 1024px) {
    svg {
      width: 4rem;
      height: 4rem;
      fill: #8e8e8e;
    }
    span {
      font-weight: 300;
      font-size: 2rem;
    }
  }
  @media screen and (max-width: 430px) {
    svg {
      width: 3rem;
      height: 3rem;
      fill: #8e8e8e;
    }
    span {
      font-weight: 300;
      font-size: 1.6rem;
    }
  }
`;

const PrevBtn = styled(PaginationBtn)``;

const NextBtn = styled(PaginationBtn)``;

function ReturnPlusAnecdote({ onScrollToSection, setIsOpen }) {
  const { t } = useTranslation();
  const data = postListData.find((data) => data.to === "returnplus");
  return (
    <>
      <Header onScrollToSection={onScrollToSection} setIsOpen={setIsOpen} />
      <Container>
        <MainImgBox>
          <MainImg
            src={`${process.env.PUBLIC_URL}/assets/images/리턴소식메인.png`}
          />
          <MainTextBox>
            <MainTag>#{t(`PostList.${data.to}.tags.0`)}</MainTag>{" "}
            <MainTitle>{t(`PostList.${data.to}.title`)}</MainTitle>
            <MainDate>August 08, 2024</MainDate>
          </MainTextBox>
        </MainImgBox>
        <ContentBox>
          {data.content.map((item, index) => (
            <Content key={index}>
              <Question>{t(`PostList.${data.to}.content.${index}.q`)}</Question>
              <Answer>{t(`PostList.${data.to}.content.${index}.a`)}</Answer>
            </Content>
          ))}
        </ContentBox>
        <TagList>
          {data.tag.map((_, index) => (
            <TagItem key={index}>
              #{t(`PostList.${data.to}.tags.${index}`)}
            </TagItem>
          ))}
        </TagList>
        <PaginationBox>
          <PrevBtn>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="#e1d1d1d"
            >
              <path d="m266-466 234 234-20 20-268-268 268-268 20 20-234 234h482v28H266Z" />
            </svg>
            <span>PREV</span>
          </PrevBtn>
          <NextBtn>
            <span>NEXT</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="#e1d1d1d"
            >
              <path d="M694-466H212v-28h482L460-728l20-20 268 268-268 268-20-20 234-234Z" />
            </svg>
          </NextBtn>
        </PaginationBox>
        <PostDetailSlider />
      </Container>
      <Footer onScrollToSection={onScrollToSection} setIsOpen={setIsOpen} />
    </>
  );
}
export default ReturnPlusAnecdote;
