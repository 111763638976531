export const workDummyData = [
  {
    off: `블록체인으로 여는 
새로운 금융 서비스 `,
    on: `리턴플러스는 블록체인 기술을 통해 누구나 쉽게 이용할 수 있는 혁신적인 금융 서비스를 개발하고 있습니다. 
복잡한 금융 절차를 간소화하고, 투명하고 안전한 거래 환경을 조성하여 새로운 금융 시대를 열어가고 있습니다.`,
  },
  {
    off: `고객 맞춤형 
블록체인 기술 개발`,
    on: `고객의 다양한 요구를 충족시키기 위해 프라이빗과 퍼블릭 블록체인 기술을 연구하고 개발합니다. 
최적의 솔루션을 제공하여 고객이 원하는 서비스를 정확하게 구현합니다.`,
  },
  {
    off: `스마트 컨트랙트와 토큰화로 
비즈니스 혁신`,
    on: `스마트 컨트랙트와 토큰화의 장점을 활용하여 새로운 비즈니스 모델을 창출합니다. 
자동화된 계약 실행과 실물 자산의 디지털화를 통해 효율성과 신뢰성을 높이고, 고객에게 새로운 투자 기회를 제공합니다.`,
  },
  {
    off: `컨설팅부터 운영까지 
원스톱 서비스 제공`,
    on: `컨설팅, 시스템 개발, 운영에 이르는 전 과정을 하나로 묶어 End-to-End 서비스를 제공합니다. 
고객은 복잡한 과정을 거치지 않고도 하나의 창구에서 완벽한 솔루션을 받을 수 있습니다.`,
  },
];

export const historyDummyData = [
  {
    year: "2024",
    activities: [
      {
        time: "2024.10",
        activity: "주요 금융기관과 파트너쉽 체결",
        img: "/assets/images/blank.jpg",
      },
      {
        time: "2024.07",
        activity: "'한조각'플랫폼 개발 착수",
        img: "/assets/images/blank.jpg",
      },
      {
        time: null,
        activity: "지도 정보에 기반한 토지 통합 정보 제공 시스템 특허 출원",
        img: "/assets/images/returnplus_map_patent.png",
      },
      {
        time: null,
        activity:
          "블록체인기반 NFT를 이용한 실물 자산의 디지털 자산화 시스템 특허 출원",
        img: "/assets/images/blank.jpg",
      },
      {
        time: null,
        activity: "증권형 토큰을 이용햔 대출 서비스 특허 출원",
        img: "/assets/images/blank.jpg",
      },
      {
        time: "2024.06",
        activity: "벤처기업 인증 획득",
        img: "/assets/images/returnplus_venture_ certification.png",
      },
      {
        time: "2024.05",
        activity: "주식회사 리턴플러스 법인 설립",
        img: null,
      },
    ],
  },
];



export const slideDummyData = [
  {
    title: `리턴 플러스 - 신한투자증권1
금융 및 공공 블록체인 공동사업
MOU 체결`,
    content: `블록체인 신금융 인프라 기업 리턴플러스가 금융기관, 공고익관 대상 블록체인 인프라 확산을 
위해 블록체인 금융 대표 기업 신한투자증권과 전략적 업무 협약을 체결했다고 10일 밝혔다.
`,
    url: `/news/1`,
    bg: `/assets/images/slide1.png`,
  },
  {
    title: `리턴 플러스 - 신한투자증권2
금융 및 공공 블록체인 공동사업
MOU 체결`,
    content: `블록체인 신금융 인프라 기업 리턴플러스가 금융기관, 공고익관 대상 블록체인 인프라 확산을 
위해 블록체인 금융 대표 기업 신한투자증권과 전략적 업무 협약을 체결했다고 10일 밝혔다.
`,
    url: `/news/2`,
    bg: `/assets/images/slide2.png`,
  },
  {
    title: `리턴 플러스 - 신한투자증권3
금융 및 공공 블록체인 공동사업
MOU 체결`,
    content: `블록체인 신금융 인프라 기업 리턴플러스가 금융기관, 공고익관 대상 블록체인 인프라 확산을 
위해 블록체인 금융 대표 기업 신한투자증권과 전략적 업무 협약을 체결했다고 10일 밝혔다.
`,
    url: `/news/3`,
    bg: `/assets/images/slide3.png`,
  },
];

export const newsDummyData = [
  {
    title: "주식회사 리턴플러스, 벤처기업확인서 획득으로 혁신적인 성장 가속화",
    date: "2024-03-14",
    image: "/assets/images/blank.jpg",
    content:
      "주식회사 리턴플러스는 최근 벤처기업확인서를 획득하며 국내 혁신기업으로서의 입지를 더욱 굳혔다. 주식회사 리턴플러스는 최근 벤처기업확인서를 획득하며 국내 혁신기업으로서의 입지를 더욱 굳혔다.",
    tags: ["벤처인증"],
  },
  {
    title: "주식회사 리턴플러스, 벤처기업확인서 획득으로 혁신적인 성장 가속화",
    date: "2024-03-14",
    image: "/assets/images/blank.jpg",
    content:
      "주식회사 리턴플러스는 최근 벤처기업확인서를 획득하며 국내 혁신기업으로서의 입지를 더욱 굳혔다. 주식회사 리턴플러스는 최근 벤처기업확인서를 획득하며 국내 혁신기업으로서의 입지를 더욱 굳혔다.",
    tags: [
      "벤처인증",
      "벤처인증",
      "벤처인증",
      "벤처인증",
      "벤처인증",
      "벤처인증",
      "벤처인증",
      "벤처인증",
      "벤처인증",
      "벤처인증",
      "벤처인증",
      "벤처인증",
      "벤처인증",
      "벤처인증",
      "벤처인증",
      "벤처인증",
      "벤처인증",
      "벤처인증",
      "벤처인증",
      "벤처인증",
    ],
  },
];