import styled from "styled-components";
import { workDummyData } from "../../api/dummyData";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 10rem 10rem 10rem 17rem;
  overflow: hidden;
  @media screen and (max-width: 1024px) {
    padding: 6rem 6rem 6rem 11rem;
  }
  @media screen and (max-width: 430px) {
    padding: 5rem 2rem 5rem 2rem;
  }
`;

const Title = styled.div`
  font-size: 2.5rem;
  font-weight: bold;
  white-space: pre-wrap;
  @media screen and (max-width: 430px) {
    font-size: 1.8rem;
    line-height: 1.2;
  }
`;

const WorkInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  position: relative;
  @media screen and (max-width: 430px) {
    margin: 0;
    gap: 2rem;
  }
`;

const ItemBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  gap: 2rem;
  transform: translateX(${(props) => props.sliderX}px);
  transition: transform 0.5s ease;
  @media screen and (max-width: 1024px) {
  }

  @media screen and (max-width: 430px) {
  }
`;

const WorkItem = styled.div`
  min-width: 671px;
  height: 428px;
  border-radius: 0.5rem;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 0 0 5.125rem;
  box-shadow: 5.6732px 5.6732px 5.6732px rgba(0, 0, 0, 0.36);
  background-color: ${({ $isSelected }) =>
    $isSelected ? "rgb(5 ,3, 3, 0.3)" : "#fafafa"};
  color: ${({ $isSelected }) => $isSelected && "#fafafa"};
  opacity: ${(props) => (props.$isVisible ? 1 : 0)};
  /* transform: ${(props) =>
    props.$isVisible ? "translateY(0)" : "translateY(80px)"};
  transition: opacity 2s ease 0.3s, transform 2s ease 0.3s;
  &:nth-child(even) {
    transition-delay: 0.6s;
  } */

  @media screen and (max-width: 1024px) {
    min-width: 671px;
  }

  @media screen and (max-width: 430px) {
    min-width: 310px;
    height: 214px;
    padding: 0 0 0 0.8rem;
  }
`;

const OnContent = styled.span`
  font-size: 1.4rem;
  line-height: 1.2;
  white-space: pre-wrap;
  font-family: "Pretendard-SemiBold";
  word-break: break-word;
  @media (min-width: 431px) and (max-width: 577px) {
    padding: 5rem 0;
    font-size: 2rem;
  }
  @media screen and (max-width: 430px) {
    padding: 5.2rem 0;
    font-size: 0.9rem;
  }
`;

const OffContent = styled.span`
  font-size: 1.556rem;
  font-weight: 600;
  white-space: pre-wrap;
  color: #1c1c1e;
  @media screen and (max-width: 1024px) {
    padding: 6.8rem 0;
  }
  @media screen and (max-width: 430px) {
    padding: 2.5rem 0;
    font-size: 1rem;
  }
`;

const Img = styled.img`
  position: absolute;
  bottom: 1rem;
  right: 1.5rem;
  width: 2rem;
  @media screen and (max-width: 430px) {
    right: 1rem;
    width: 1rem;
  }
`;

const BtnBox = styled.div`
  display: flex;
  justify-content: end;
  gap: 1rem;
`;

const Btn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dfdfe3;
  border: none;
  padding: 0.2rem;
  border-radius: 9999px;
  cursor: pointer;
  @media screen and (max-width: 1024px) {
    svg {
      width: 28px;
      height: 28px;
    }
  }
`;

const LeftBtn = styled(Btn)`
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
`;

const RightBtn = styled(Btn)`
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
`;

function Work() {
  const { t } = useTranslation();
  const [selected, setSelected] = useState([]);
  const [visibleWorkItems, setVisibleWorkItems] = useState([]);
  const [sliderX, setSliderX] = useState(0);
  const [maxSliderX, setMaxSliderX] = useState(0);
  const [disableNext, setDisableNext] = useState(false);
  const [disablePrev, setDisablePrev] = useState(true);
  const [itemWidth, setItemWidth] = useState(703); // Default to 703 for screens > 1024px

  const workItemsRef = useRef([]);
  const visibleItems = 1; // Number of items visible at once (adjust if necessary)

  // Set itemWidth based on window width
  const updateItemWidth = () => {
    if (window.innerWidth <= 430) {
      setItemWidth(342);
    } else {
      setItemWidth(703);
    }
  };

  useEffect(() => {
    // Call on mount
    updateItemWidth();

    // Add event listener for window resize
    window.addEventListener("resize", updateItemWidth);

    // Clean up event listener
    return () => window.removeEventListener("resize", updateItemWidth);
  }, []);

  useEffect(() => {
    // Calculate max slider distance based on the number of items
    const totalItems = workDummyData.length;
    const totalWidth = totalItems * itemWidth;
    const maxTranslateX = totalWidth - visibleItems * itemWidth; // Maximum scrollable distance

    setMaxSliderX(maxTranslateX);
  }, [itemWidth]);

  useEffect(() => {
    // Check if next/prev buttons should be disabled
    setDisablePrev(sliderX === 0); // Disable prev if at the start
    setDisableNext(sliderX <= -maxSliderX); // Disable next if at the end
  }, [sliderX, maxSliderX]);

  const handleClick = (index) => {
    setSelected((prev) =>
      prev.includes(index)
        ? prev.filter((item) => item !== index)
        : [...prev, index]
    );
  };

  useEffect(() => {
    const workObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = workItemsRef.current.indexOf(entry.target);
          if (entry.isIntersecting && index !== -1) {
            setVisibleWorkItems((prev) => [...prev, index]);
          }
        });
      },
      { threshold: 0.3 }
    );

    const workItems = [...workItemsRef.current];

    workItems.forEach((item) => {
      if (item) workObserver.observe(item);
    });

    return () => {
      workItems.forEach((item) => {
        if (item) workObserver.unobserve(item);
      });
    };
  }, []);

  const nextBtnHandler = () => {
    setSliderX((prev) => Math.max(prev - itemWidth, -maxSliderX));
  };

  const prevBtnHandler = () => {
    setSliderX((prev) => Math.min(prev + itemWidth, 0));
  };

  return (
    <Container>
      <WorkInner>
        <Title>{t("What We Do")}</Title>
        <ItemBox sliderX={sliderX}>
          {workDummyData.map((text, index) => (
            <WorkItem
              key={index}
              onClick={() => handleClick(index)}
              $isSelected={selected.includes(index)}
              ref={(el) => (workItemsRef.current[index] = el)}
              $isVisible={visibleWorkItems.includes(index)}
            >
              {selected.includes(index) ? (
                <>
                  <OnContent>{t(`workDummyData.on${index + 1}`)}</OnContent>{" "}
                  <Img
                    src={`${process.env.PUBLIC_URL}/assets/images/cancel.png`}
                  />
                </>
              ) : (
                <>
                  <OffContent>{t(`workDummyData.off${index + 1}`)}</OffContent>{" "}
                  <Img
                    src={`${process.env.PUBLIC_URL}/assets/images/add.png`}
                  />
                </>
              )}
            </WorkItem>
          ))}
        </ItemBox>
        <BtnBox>
          <LeftBtn onClick={prevBtnHandler} disabled={disablePrev}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="48px"
              viewBox="0 -960 960 960"
              width="48px"
              fill="#6a6a6d"
            >
              <path d="M418.11-481.76 590.2-309.67q10.71 11.47 10.21 26.93-.5 15.45-11.21 27.17-11.48 11.48-27.93 11.48-16.46 0-27.18-11.48L335.8-454.09q-6.21-5.17-9.08-12.27-2.87-7.09-2.87-15.4 0-7.38 2.87-14.53 2.87-7.16 9.08-12.38L535.09-708.2q10.72-11.47 27.18-11.47 16.45 0 27.93 11.47 10.71 11.73 10.71 27.68t-10.71 27.43L418.11-481.76Z" />
            </svg>
          </LeftBtn>
          <RightBtn onClick={nextBtnHandler} disabled={disableNext}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="48px"
              viewBox="0 -960 960 960"
              width="48px"
              fill="#6a6a6d"
            >
              <path d="M518.65-481.76 347.57-653.09q-11.72-11.48-11.22-26.93.5-15.46 12.22-27.18 10.47-11.47 26.93-11.47t27.93 11.47l198.53 198.53q5.24 5.24 8.59 12.33 3.36 7.1 3.36 14.58 0 8.24-3.36 15.34-3.35 7.09-8.59 12.33L402.43-254.57q-11.47 11.48-27.43 10.98-15.96-.5-26.43-11.98-11.72-11.71-11.72-27.67t11.72-27.43l170.08-171.09Z" />
            </svg>
          </RightBtn>
        </BtnBox>
      </WorkInner>
    </Container>
  );
}

export default Work;
