import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import ClipMask from "./ClipMask";
import Landing from "./Landing";
import NewsRoom from "./NewsRoom";
import Research from "./Research";
import Service from "./Service";
import Sidebar from "../../components/Sidebar/Sidebar";
import Description from "./Description";
import Work from "./Work";
import History from "./History";
import Vision from "./Vision";
import Video from "./Video";

function Main({ isOpen, setIsOpen, sectionRef, scrollToSection }) {
  const [src, setSrc] = useState(null);

  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.scrollTo) {
      scrollToSection(location.state.scrollTo);
    }
  }, [location, scrollToSection]);

  return (
    <>
      <Header onScrollToSection={scrollToSection} setIsOpen={setIsOpen} />
      <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />
      <div ref={(el) => (sectionRef.current["landing"] = el)}>
        <Landing onScrollToSection={scrollToSection} setIsOpen={setIsOpen} />
      </div>
      <div ref={(el) => (sectionRef.current["service"] = el)}>
        <Service />
      </div>
      <ClipMask />
      <Description />
      <Video src={src} setSrc={setSrc} />
      <Vision />
      <Work />
      <History setSrc={setSrc} />
      <div ref={(el) => (sectionRef.current["research"] = el)}>
        <Research onScrollToSection={scrollToSection} />
      </div>
      <div ref={(el) => (sectionRef.current["newsroom"] = el)}>
        <NewsRoom />
      </div>
      <Footer onScrollToSection={scrollToSection} setIsOpen={setIsOpen} />
    </>
  );
}

export default Main;
