import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { postListData } from "../../api/staticData";
import { useTranslation } from "react-i18next";

const SliderContainer = styled.div`
  position: relative;
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 5rem 9.75em;
  @media screen and (max-width: 1024px) {
    padding: 5rem 3rem;
  }
  @media screen and (max-width: 430px) {
    margin-top: 0rem;
    padding: 0 1.563rem;
    gap: 1.4rem;
  }
`;

const SliderLabel = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
`;

const SliderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
  gap: 3rem;
  @media screen and (max-width: 1024px) {
    padding: 0;
  }
`;

const SliderContent = styled.ul`
  width: 100%;
  display: flex;
  gap: 1.5rem;
  /* transition: transform 0.5s ease-in-out;
  transform: translateX(-${(props) => props.translateX}px); */
  @media screen and (max-width: 1024px) {
    margin-bottom: 2rem;
    /* margin-left: 5rem; */
  }
  @media screen and (max-width: 430px) {
    /* margin-left: 17.5rem; */
  }
`;

const SlideItem = styled.li`
  flex: 0 0 ${(props) => props.slideWidth}px;
  width: 100%;
  min-width: 9.125rem;
  margin-bottom: 3rem;
  overflow: hidden;
`;

const SlideLink = styled(Link)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media screen and (max-width: 1024px) {
    gap: 0.4rem;
  }
`;

const SlideImg = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const SlideText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  @media screen and (max-width: 1024px) {
    gap: 0.1rem;
  }
`;

const SlideTag = styled.span`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: #91004e;
  font-size: 1.125rem;
  @media screen and (max-width: 1024px) {
    font-size: 0.9rem;
  }
`;

const SlideTitle = styled.span`
  font-weight: 500;
  white-space: pre-wrap;
  word-break: keep-all;
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-height: 1.2;
  font-size: 1.5rem;
  @media screen and (max-width: 1024px) {
    font-size: 1rem;
  }
`;

const NavButton = styled.button`
  display: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  ${(props) => (props.left ? "left: 5rem;" : "right: 5rem;")}
  transform: translateY(-50%);
  background: transparent;
  border: 1px solid #e5e5e5;
  font-size: 1.4rem;
  padding: 0.4rem;
  border-radius: 100%;
  cursor: ${(props) =>
    props.isDisabled
      ? "not-allowed"
      : props.isTransitioning
      ? "not-allowed"
      : "pointer"};
  z-index: 10;
  transition: background 0.3s, opacity 0.3s;
  opacity: ${(props) =>
    props.isDisabled ? "0.5" : props.isTransitioning ? "0.5" : "1"};
  visibility: ${(props) => (props.isDisabled ? "hidden" : "visible")};

  &:hover {
    background: ${(props) =>
      props.isDisabled ? "none" : "rgba(0, 0, 0, 0.3)"};
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

function PostDetailSlider() {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [slideWidth, setSlideWidth] = useState(384); // 기본값 320px

  // 전환 중인지 여부
  const [isTransitioning, setIsTransitioning] = useState(false);

  // 슬라이드 간 간격(px)
  const gap = 24; // CSS의 gap: 1rem (16px)

  // 화면 너비 추적
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

  // 터치 이벤트 상태
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);

  // 슬라이더 아이템 설정
  useEffect(() => {
    if (postListData.length > 0) {
      setCurrentIndex(0);
    }
  }, []);

  // 화면 크기 변경 시 상태 업데이트
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 430) {
        setSlideWidth(146);
        setCurrentIndex(0); // 슬라이드 인덱스 초기화
      } else if (width <= 1024) {
        setSlideWidth(237);
        setCurrentIndex(0); // 슬라이드 인덱스 초기화
      } else {
        setSlideWidth(384);
        setCurrentIndex(0); // 슬라이드 인덱스 초기화
      }

      setIsMobile(width <= 1024);
    };

    handleResize(); // 초기 설정
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // 현재 슬라이드의 translateX 값 계산
  const translateX = (slideWidth + gap) * currentIndex;

  // 슬라이드 전환 종료 핸들러
  const handleTransitionEnd = () => {
    setIsTransitioning(false);
  };

  // 자동 슬라이드 기능
  const autoSlideTimer = useRef(null);

  const startAutoSlide = () => {
    if (autoSlideTimer.current) return;
    autoSlideTimer.current = setInterval(() => {
      handleNext();
    }, 2000);
  };

  const stopAutoSlide = () => {
    if (autoSlideTimer.current) {
      clearInterval(autoSlideTimer.current);
      autoSlideTimer.current = null;
    }
  };

  // 자동 슬라이드 시작 및 정지
  useEffect(() => {
    if (postListData.length > 1 && postListData.length >= 5) {
      startAutoSlide();
    }
    return () => stopAutoSlide();
  }, []);

  // 마우스 호버 핸들러
  const handleMouseEnter = () => {
    stopAutoSlide();
  };

  const handleMouseLeave = () => {
    startAutoSlide();
  };

  // 슬라이드 이동 함수
  const handleNext = () => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setCurrentIndex((prevIndex) => {
      if (prevIndex >= postListData.length - 2) {
        return 0; // 마지막 슬라이드에서 처음으로 이동
      } else {
        return prevIndex + 1;
      }
    });
    resetAutoSlide();
  };

  const handlePrev = () => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setCurrentIndex((prevIndex) => {
      if (prevIndex === 0) {
        return postListData.length - 1; // 처음 슬라이드에서 마지막으로 이동
      } else {
        return prevIndex - 1;
      }
    });
    resetAutoSlide();
  };

  // 자동 슬라이드 리셋
  const resetAutoSlide = () => {
    stopAutoSlide();
    startAutoSlide();
  };

  // 터치 이벤트 핸들러
  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    touchEndX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    const deltaX = touchStartX.current - touchEndX.current;
    const threshold = 50; // 스와이프 감지 임계값

    if (deltaX > threshold) {
      // 왼쪽으로 스와이프
      handleNext();
    } else if (deltaX < -threshold) {
      // 오른쪽으로 스와이프
      handlePrev();
    }

    // 초기화
    touchStartX.current = 0;
    touchEndX.current = 0;
  };
  return (
    <SliderContainer
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onTouchStart={isMobile ? handleTouchStart : null}
      onTouchMove={isMobile ? handleTouchMove : null}
      onTouchEnd={isMobile ? handleTouchEnd : null}
    >
      {!isMobile && (
        <NavButton
          left
          onClick={handlePrev}
          aria-label="Previous Slide"
          isTransitioning={isTransitioning}
          isDisabled={isTransitioning}
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/arrow_left.svg`}
            alt="Arrow Left"
          />
        </NavButton>
      )}
      <SliderLabel>{t("다른리턴플러스소식")}</SliderLabel>
      <SliderWrapper>
        <SliderContent
          translateX={translateX}
          onTransitionEnd={handleTransitionEnd}
        >
          {postListData.map((item, index) => (
            <SlideItem key={item._id} slideWidth={slideWidth}>
              <SlideLink
                to={`/post/${item.to}`}
                state={{ state: postListData }}
              >
                <SlideImg
                  src={`${process.env.PUBLIC_URL}/assets/images/리턴소식썸네일.png`}
                  alt={`${item.title} 이미지`}
                />
                <SlideText>
                  <SlideTag>
                    #{item && t(`PostList.${item.to}.tags.0`)}
                  </SlideTag>
                  <SlideTitle>
                    {item && t(`PostList.${item.to}.title`)}
                  </SlideTitle>
                </SlideText>
              </SlideLink>
            </SlideItem>
          ))}
        </SliderContent>
      </SliderWrapper>
      {!isMobile && (
        <NavButton
          onClick={handleNext}
          aria-label="Next Slide"
          isTransitioning={isTransitioning}
          isDisabled={isTransitioning}
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/arrow_right.svg`}
            alt="Arrow Right"
          />
        </NavButton>
      )}
    </SliderContainer>
  );
}

export default React.memo(PostDetailSlider);
