import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createGlobalStyle } from "styled-components";
import "./i18n";

const GlobalStyle = createGlobalStyle`
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;  
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
  display: block;
}

html{
  font-size: 100%;
  scroll-behavior: smooth; 
}
body {
  line-height: 1;
  font-family: 'Pretendard', Noto Sans;
  background-color: #f8f8f8;
  padding: 0;
	color: #222222;
  width: 100%;
}
button{
  font-family: 'Pretendard', Noto Sans;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  text-decoration: none;
  color:inherit;
}
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}
@font-face {
  font-family: 'Pretendard-Light';
  src: url('/assets/fonts/woff2/Pretendard-Light.woff2') format('woff2'),
        url('/assets/fonts/woff/Pretendard-Light.woff') format('woff');
}
@font-face {
  font-family: 'Pretendard-Regular';
  src: url('/assets/fonts/woff2/Pretendard-Regular.woff2') format('woff2'),
        url('/assets/fonts/woff/Pretendard-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Pretendard-Medium';
  src: url('/assets/fonts/woff2/Pretendard-Medium.woff2') format('woff2'),
        url('/assets/fonts/woff/Pretendard-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Pretendard-SemiBold';
  src: url('/assets/fonts/woff2/Pretendard-SemiBold.woff2') format('woff2'),
        url('/assets/fonts/woff/Pretendard-SemiBold.woff') format('woff');
}
`;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <GlobalStyle />
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();