import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes, css } from "styled-components";
import ResearchForm from "../../components/ResearchForm/ResearchForm";
import Summary from "../../components/Summary/Summary";
import { useTranslation } from "react-i18next";
const flyIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(50px); 
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideIn = keyframes`
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const Div = styled.div`
  width: 100%;
  height: 380vh;
  background-color: #fff;
  /* tablet */
  @media screen and (min-width: 1025px) and (max-width: 1280px) {
    height: 420vh;
  }
  @media screen and (min-width: 1025px) and (max-width: 1280px) and (max-height: 721px) {
    height: 450vh;
  }
  @media screen and (min-width: 854px) and (max-width: 1024px) {
    height: 360vh;
  }
  @media screen and (min-width: 854px) and (max-width: 1024px) and (max-height: 600px) {
    height: 660vh;
  }
  @media screen and (min-width: 821px) and (max-width: 853px) {
    height: 380vh;
  }
  @media screen and (min-width: 769px) and (max-width: 820px) {
    height: 400vh;
  }
  @media screen and (min-width: 481px) and (max-width: 768px) {
    height: 430vh;
  }
  @media screen and (min-width: 481px) and (max-width: 541px) {
    height: 570vh;
  }
  /* moblie */
  @media screen and (max-width: 480px) {
    height: 500vh;
  }
  @media screen and (max-width: 480px) and (max-height: 667px) {
    height: 640vh;
  }
  @media screen and (max-width: 480px) and (min-height: 668px) and (max-height: 741px) {
    height: 580vh;
  }
  @media screen and (max-width: 480px) and (min-height: 742px) and (max-height: 845px) {
    height: 520vh;
  }
  @media screen and (max-width: 480px) and (min-height: 846px) and (max-height: 882px) {
    height: 510vh;
  }
`;

const Wrapper = styled.div`
  display: flex;
  padding: 3rem;
  width: 100%;
  height: 55%;
  background-color: #050303;
  @media (max-width: 1125px) {
    flex-direction: column;
    height: auto;
    padding: 0;
  }
`;

const SummaryBox = styled.div`
  height: auto;
  line-height: 1.8rem;
  @media (max-width: 1125px) {
    height: 70%;
    position: relative;
    z-index: 1;
    padding: 5rem 2rem 0rem 2rem;
  }
`;

const SummaryImage = styled.div`
  width: 16.438rem;
  height: 3rem;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  @media (max-width: 480px) {
    width: 9rem;
    height: 1.5rem;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    width: 13rem;
    height: 2.1rem;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    width: 14rem;
    height: 2.3rem;
  }
`;

const SummaryContentBox = styled.div`
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
`;
const SummaryContent = styled.p`
  color: #fff;
  font-family: "Pretendard-Regular";
  font-size: 1.5rem;
  @media (max-width: 480px) {
    font-size: 1rem;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    font-size: 1.5rem;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    font-size: 1.5rem;
  }
`;
const ImageBox = styled.div`
  width: 80%;
  height: 100%;
  padding: 3rem;
  @media (max-width: 1125px) {
    width: 100%;
    padding: 0rem;
    height: 100vh;
    position: relative;
  }
`;

const Image = styled.div`
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  object-fit: cover;
  width: 100%;
  height: ${(props) => props.height};
  margin-top: ${(props) => props.$mt};
  background-repeat: no-repeat;
`;

const ResearchVideoBox = styled.div`
  width: 100%;
  height: 65%;
  z-index: 0;
  margin-top: 5rem;
  @media screen and (max-width: 1125px) {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
  }
`;

const ResearchVideo = styled.video`
  width: 100%;
  background-size: cover;
  background-position: center;
  object-fit: cover;
  background-repeat: no-repeat;
  @media (max-width: 1125px) {
    margin-top: 0;
    height: 100%;
    filter: brightness(0.2);
  }
`;

const ContentBox = styled.div`
  width: 165vh;
  padding: 3rem;
  /* tablet */
  @media screen and (min-width: 1025px) and (max-width: 1280px) {
    width: 100%;
    height: 100%;
    padding: 3rem 2rem 0 2rem;
  }
  @media screen and (min-width: 854px) and (max-width: 1024px) {
    width: 100%;
    height: 140vh;
    padding: 10rem 2rem 0 2rem;
  }
  @media screen and (min-width: 854px) and (max-width: 1024px) and (max-height: 600px) {
    width: 100%;
    height: 300vh;
    padding: 8rem 2rem 0 2rem;
  }
  @media screen and (min-width: 821px) and (max-width: 853px) {
    width: 100%;
    height: 150vh;
    padding: 8rem 2rem 0 2rem;
  }
  @media screen and (min-width: 769px) and (max-width: 820px) {
    width: 100%;
    height: 160vh;
    padding: 10rem 2rem 0 2rem;
  }
  @media screen and (min-width: 481px) and (max-width: 768px) {
    width: 100%;
    height: 180vh;
    padding: 10rem 2rem 0 2rem;
  }
  @media screen and (min-width: 481px) and (max-width: 541px) {
    width: 100%;
    height: 270vh;
    padding: 10rem 2rem 0 2rem;
  }
  /* moblie */
  @media screen and (max-width: 480px) {
    width: 100%;
    height: 240vh;
    padding: 10rem 2rem 0 2rem;
  }
  @media screen and (max-width: 480px) and (max-height: 667px) {
    width: 100%;
    height: 320vh;
    padding: 7rem 2rem 0 2rem;
  }
  @media screen and (max-width: 480px) and (min-height: 668px) and (max-height: 741px) {
    width: 100%;
    height: 290vh;
    padding: 8rem 2rem 0 2rem;
  }
  @media screen and (max-width: 480px) and (min-height: 742px) and (max-height: 845px) {
    width: 100%;
    height: 250vh;
    padding: 7rem 2rem 0 2rem;
  }
  @media screen and (max-width: 480px) and (min-height: 846px) and (max-height: 882px) {
    width: 100%;
    height: 240vh;
    padding: 7rem 2rem 0 2rem;
  }
`;

const InfoBox = styled.div`
  width: 100%;
  height: 2rem;
  font-family: "Pretendard-Light";
  font-size: 1rem;
  display: flex;
  color: #fff;
`;

const Subject = styled.p`
  line-height: 2.3rem;
  font-family: "Pretendard-SemiBold";
  font-size: 2rem;
  height: auto;
  color: #fff;
  @media (max-width: 480px) {
    font-size: 1.5rem;
    line-height: 1.7rem;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    font-size: 1.5rem;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
  }
  @media (min-width: 1025px) and (max-width: 1700px) {
  }
`;

const SubjectSub = styled.p`
  font-family: "Pretendard-Regular";
  color: #fff;
  line-height: 1.3rem;
  @media (max-width: 1125px) {
    margin-top: 1rem;
  }
`;
const ResearchformBox = styled.div`
  margin-top: 3rem;
`;
const HanjokakBox = styled.div`
  width: 100%;
  height: 15%;
  @media (max-width: 480px) {
    margin-top: 5rem;
    height: 9%;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    margin-top: 5rem;
    height: 12%;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    margin-top: 7rem;
    height: 12%;
  }
`;

const HanjokaklSubject = styled.p`
  font-family: "Pretendard-SemiBold";
  font-size: 2.5rem;
  margin-top: 7rem;
  padding-left: 11rem;
  @media (max-width: 480px) {
    padding-left: 2.5rem;
    font-size: 2rem;
  }
`;
const SelectBox = styled.div`
  width: 8rem;
  height: 3.5rem;
  border-radius: 2rem;
  font-family: "Pretendard-SemiBold";
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  gap: 0.3rem;
  margin-top: 2rem;
  margin-left: 10rem;
  opacity: ${(props) => (props.$isVisible ? 1 : 0)};
  animation: ${(props) =>
    props.$isVisible
      ? css`
          ${flyIn} 2s ease-out
        `
      : "none"};
  @media (max-width: 480px) {
    margin-left: 2rem;
    margin-bottom: 2rem;
    width: 5rem;
    height: 2.5rem;
  }
`;

const SelectInnerBox = styled.div`
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 2rem;
  background-color: ${(props) => (props.$isSelected ? "black" : "white")};
  color: ${(props) => (props.$isSelected ? "white" : "black")};
  @media (max-width: 480px) {
    height: 2rem;
    font-size: 0.75rem;
  }
`;
const HanjokakInner = styled.div`
  width: 100%;
  height: 60%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  @media (max-width: 1024px) {
    display: block;
  }
`;

const HanjokakImageBox = styled.div`
  width: 25rem;
  height: 9rem;
  opacity: 0;
  animation: ${slideIn} 3s ease forwards;
  animation-play-state: paused;
  @media (max-width: 480px) {
    width: 20rem;
    height: 7rem;
    margin: 0 auto;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    width: 25rem;
    height: 9rem;
    margin: 0 auto;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    width: 30rem;
    height: 10.8rem;
    margin: 0 auto;
  }
`;

const HanjokakContentBox = styled.div`
  width: 50%;
  margin: 0;
  padding: 0 2rem 0 10rem;
  opacity: 0;
  animation: ${slideIn} 3s ease forwards;
  animation-play-state: paused;
  @media (max-width: 480px) {
    width: 100%;
    padding: 3rem 2rem 0 2rem;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    width: 100%;
    padding: 3rem 2rem 0 2rem;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    width: 100%;
    padding: 3rem 2rem 0 2rem;
  }
`;

const HanjokakSubject = styled.p`
  font-family: "Pretendard-Medium";
  padding: 2rem 0 1rem 0;
  color: #a64424;
  font-size: 1.4rem;
  opacity: 0;
  animation: ${slideIn} 3s ease forwards;
  animation-play-state: paused;
  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

const HanjokakContent = styled.p`
  opacity: 0;
  font-family: "Pretendard-Regular";
  font-size: 1.1rem;
  color: black;
  animation: ${slideIn} 3s ease forwards;
  @media (max-width: 480px) {
    font-size: 0.8rem;
  }
`;

const ContentData = {
  HanjokakPlatformSelect: {},
};

function Research({ onScrollToSection }) {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const items = Object.keys(ContentData);
  const hanjokakImageRef = useRef(null);
  const hanjokakContentRef = useRef(null);
  const hanjokakSubjectRef = useRef(null);
  const { t, i18n } = useTranslation();
  const videoRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.3 }
    );

    const currentImageRef = hanjokakImageRef.current;
    if (currentImageRef) {
      observer.observe(currentImageRef);
    }

    return () => {
      if (currentImageRef) {
        observer.unobserve(currentImageRef);
      }
    };
  }, []);

  useEffect(() => {
    const videoElement = videoRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.intersectionRatio >= 0.5) {
            videoElement.play();
          } else {
            videoElement.pause();
          }
        });
      },
      { threshold: 0.5 }
    );

    if (videoElement) {
      observer.observe(videoElement);
    }

    return () => {
      if (videoElement) {
        observer.unobserve(videoElement);
      }
    };
  }, []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setSelectedIndex((prevIndex) => {
        const nextIndex = (prevIndex + 1) % items.length;
        return nextIndex;
      });
    }, 3000);

    return () => clearInterval(intervalId);
  }, [items.length]);
  return (
    <Div>
      <Wrapper>
        <ImageBox>
          <SummaryBox>
            <SummaryImage src="/assets/HeaderLogo.png" />
            <SummaryContentBox>
              <SummaryContent>
                {t("Research.SummaryContent1")}
                <br />
                {t("Research.SummaryContent2")}
              </SummaryContent>
              <SummaryContent>
                {t("Research.SummaryContent3")} <br />
                {t("Research.SummaryContent4")}
              </SummaryContent>
              <SummaryContent>{t("Research.SummaryContent5")}</SummaryContent>
              <SummaryContent>{t("Research.SummaryContent6")}</SummaryContent>
            </SummaryContentBox>
          </SummaryBox>
          <ResearchVideoBox>
            <ResearchVideo
              src="/assets/video/researchVideo.mp4"
              height="100%"
              ref={videoRef}
              loop
              muted
              playsInline
            />
          </ResearchVideoBox>
        </ImageBox>
        <ContentBox>
          <InfoBox>
            <p>{t("Research.Inquiry")}</p>
          </InfoBox>
          <Subject>{t("Research.Subject")}</Subject>
          <SubjectSub>{t("Research.SubjectSub")}</SubjectSub>
          <ResearchformBox>
            <ResearchForm />
          </ResearchformBox>
        </ContentBox>
      </Wrapper>
      <HanjokakBox>
        <HanjokaklSubject>
          {t("Research.HanjokakPlatformInfo")}
        </HanjokaklSubject>
        <SelectBox $isVisible={isVisible}>
          {items.map((key, index) => (
            <SelectInnerBox key={key} $isSelected={selectedIndex === index}>
              {t(`Research.${key}`)}
            </SelectInnerBox>
          ))}
        </SelectBox>
        <HanjokakInner>
          <HanjokakImageBox
            ref={hanjokakImageRef}
            style={{
              opacity: isVisible ? 1 : 0,
              animationPlayState: isVisible ? "running" : "paused",
            }}
          >
            {i18n.language === "ko" ? (
              <Image src="/assets/images/hanjokakimgko.png" height="100%" />
            ) : (
              <Image src="/assets/images/hanjokakimgen.png" height="100%" />
            )}
          </HanjokakImageBox>
          <HanjokakContentBox
            ref={hanjokakContentRef}
            style={{
              opacity: isVisible ? 1 : 0,
              animationPlayState: isVisible ? "running" : "paused",
            }}
          >
            <HanjokakSubject
              ref={hanjokakSubjectRef}
              style={{
                opacity: isVisible ? 1 : 0,
                animationPlayState: isVisible ? "running" : "paused",
              }}
            >
              {t("Research.HanjokakSubject1")}
              <br></br>
              {t("Research.HanjokakSubject2")}
              <br></br>
              {t("Research.HanjokakSubject3")}
            </HanjokakSubject>
            <HanjokakContent
              ref={hanjokakContentRef}
              style={{
                opacity: isVisible ? 1 : 0,
                animationPlayState: isVisible ? "running" : "paused",
              }}
            >
              {t("Research.HanjokakContent1")}
              <br></br>
              {t("Research.HanjokakContent2")}
            </HanjokakContent>
          </HanjokakContentBox>
        </HanjokakInner>
        <Summary onScrollToSection={onScrollToSection} />
      </HanjokakBox>
    </Div>
  );
}

export default Research;
