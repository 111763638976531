import React, { useState } from "react";
import styled from "styled-components";
import { PrivacyPolicyData } from "../EmailForm/PrivacyPolicyData";
import { useTranslation } from "react-i18next";
import CustomSelect from "../Select/CustomSelect";
import { MarketingData } from "../EmailForm/MarketingData";

const FormDiv = styled.div`
  margin: 0 auto;
`;

const FormBox = styled.div`
  margin-bottom: 2rem;
`;

const Label = styled.label`
  display: block;
  color: #fff;
  font-family: "Pretendard-Medium";
  margin-bottom: 0.7rem;
`;

const InputBox = styled.div`
  width: 100%;
  border-bottom: 0.1rem solid #fff;
`;

const Input = styled.input`
  width: 100%;
  height: 2.625rem;
  padding: 0.6rem;
  color: #fff;
  border: none;
  background: #050303;
`;
const LabelSpan = styled.span`
  font-family: "Pretendard-Medium";
  color: #e07b27;
`;
const EmailBox = styled.div`
  width: 100%;
  display: flex;
`;
const EmailInputBox = styled.div`
  width: 55%;
  border-bottom: 0.1rem solid #fff;
`;
const EmailInput = styled.input`
  width: 55%;
  padding-left: 0.7rem;
  height: 2.5rem;
  color: #fff;
  border: none;
  background: none;
`;

const EmailDomainSignBox = styled.div`
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EmailSpan = styled.span`
  font-family: "Pretendard-Medium";
  color: #fff;
  font-size: 1.5rem;
  @media (max-width: 480px) {
    font-size: 1rem;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    font-size: 1.4rem;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    font-size: 1.8rem;
  }
`;
const CustomSelectBox = styled.div`
  width: 40%;
`;
const TextAreaBox = styled.div`
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
`;
const TextArea = styled.textarea`
  width: 100%;
  height: 4.75rem;
  padding: 1rem;
  border: 0.1rem solid #ccc;
  font-family: "Pretendard-Medium";
  color: #060203;
`;
const CharCount = styled.span`
  font-size: 0.778rem;
  font-family: "Pretendard-Regular";
  color: #686969;
`;
const Checkbox = styled.input`
  display: none;
`;

const CustomCheckbox = styled.div`
  width: 1.438rem;
  height: 1.438rem;
  border: 0.1rem solid #050303;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  background: #fff;
  position: relative;
  cursor: pointer;
  &::after {
    content: "";
    display: ${(props) => (props.checked ? "block" : "none")};
    width: 0.3rem;
    height: 0.6rem;
    border: solid #050303;
    border-width: 0 0.12rem 0.12rem 0;
    transform: rotate(45deg);
    position: absolute;
    left: 7px;
    top: 3px;
  }
`;
const DownArrow = styled.div`
  width: 0.7rem;
  height: 0.7rem;
  border: solid #fff;
  margin-bottom: 0.35rem;
  margin-right: 0.5rem;
  border-width: 0 0.1rem 0.1rem 0;
  transform: rotate(45deg);
`;
const UpArrow = styled.div`
  width: 0.7rem;
  height: 0.7rem;
  border: solid #fff;
  margin-top: 0.35rem;
  margin-right: 0.5rem;
  border-width: 0 0.1rem 0.1rem 0;
  transform: rotate(225deg);
`;
const Button = styled.button`
  margin-bottom: 3rem;
  padding: 1rem;
  background-color: #fff;
  font-family: "Pretendard-SemiBold";
  font-size: 1rem;
  color: #050303;
  border: none;
  cursor: pointer;
  margin-top: 1rem;
  width: 100%;
  &:hover {
    opacity: 0.7;
  }
  @media screen and (max-width: 481px) {
    margin-top: 2rem;
  }
`;

const CheckBoxWrapper = styled.div`
  display: block;
  height: 100%;
`;
const CheckBoxSubject = styled.p`
  font-family: "Pretendard-Medium";
  font-size: 1.056rem;
  color: #fff;
  margin-bottom: 0.5rem;
`;
const CheckBoxInner = styled.div`
  width: 100%;
  height: 100%;
  display: block;
`;
const CheckBoxBox = styled.div`
  font-family: "Pretendard-Medium";
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.1rem solid #fff;
  padding: 0.5rem;
  font-size: 0.89rem;
  &:nth-child(2) {
    border-bottom: none;
    padding-left: 3rem;
  }
  &:nth-child(4) {
    border-top: 0.1rem solid #fff;
    padding-left: 3rem;
  }
  @media screen and (max-width: 481px) {
    &:nth-child(2) {
      padding-left: 2rem;
    }
    &:nth-child(4) {
      padding-left: 2rem;
    }
  }
  @media screen and (max-width: 480px) and (min-height: 846px) and (max-height: 882px) {
    &:nth-child(2) {
      padding-left: 1.5rem;
    }
    &:nth-child(4) {
      padding-left: 1.5rem;
    }
  }
`;
const CheckBoxBoxInner = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;
const CheckBoxContentInner = styled.div`
  width: 100%;
  @media screen and (max-width: 480px) and (min-height: 846px) and (max-height: 882px) {
    width: 11.9rem;
  }
`;
const CheckBoxLabel = styled.label`
  display: block;
  cursor: pointer;
  color: #fff;
  line-height: 1.3rem;
  font-size: 0.889rem;
`;

const Detail1 = styled.div`
  display: ${(props) => (props.$show ? "block" : "none")};
  background-color: #e9ecef;
  height: 10rem;
  line-height: 1rem;
  padding-left: 5rem;
  font-family: "Pretendard-Regular";
  font-size: 0.778rem;
  box-sizing: border-box;
  border-top: 0.1rem solid #fff;
  white-space: pre-wrap;
  color: #fff;
  background-color: #050303;
  @media screen and (max-width: 481px) {
    padding-left: 2.8rem;
    height: 14rem;
  }
  /* tablet */
  @media screen and (min-width: 854px) and (max-width: 1024px) {
    height: 10rem;
  }
  @media screen and (min-width: 854px) and (max-width: 1024px) and (max-height: 600px) {
    height: 10rem;
  }
  @media screen and (min-width: 821px) and (max-width: 853px) {
    height: 10rem;
  }
  @media screen and (min-width: 769px) and (max-width: 820px) {
    height: 10rem;
  }
  @media screen and (min-width: 481px) and (max-width: 768px) {
    height: 10rem;
  }
  @media screen and (min-width: 481px) and (max-width: 541px) {
    height: 12rem;
  }
  /* moblie */
  @media screen and (max-width: 480px) {
    height: 16rem;
  }
  @media screen and (max-width: 480px) and (max-height: 667px) {
    height: 16rem;
  }
  @media screen and (max-width: 480px) and (min-height: 668px) and (max-height: 741px) {
    height: 16rem;
  }
  @media screen and (max-width: 480px) and (min-height: 742px) and (max-height: 845px) {
    height: 16rem;
  }
  @media screen and (max-width: 480px) and (min-height: 846px) and (max-height: 882px) {
    height: 16rem;
  }
`;

const Detail1LastContent = styled.p`
  font-family: "Pretendard-SemiBold";
  font-size: 0.778rem;
  color: #e07b27;
`;

const Detail2 = styled.div`
  display: ${(props) => (props.$show ? "block" : "none")};
  background-color: #e9ecef;
  height: 8rem;
  padding-left: 5rem;
  font-family: "Pretendard-Regular";
  font-size: 0.778rem;
  box-sizing: border-box;
  white-space: pre-wrap;
  color: #fff;
  background-color: #050303;
  padding-bottom: 0.778rem;
  border-bottom: 0.1rem solid #fff;
  @media screen and (max-width: 481px) {
    padding-left: 2.8rem;
    height: 11rem;
  }
  @media screen and (min-width: 481px) and (max-width: 541px) {
    height: 11rem;
  }
`;

const Detail2LastContent = styled.p`
  font-family: "Pretendard-SemiBold";
  font-size: 0.778rem;
  color: #e07b27;
`;
const ResearchForm = () => {
  const { t, i18n } = useTranslation();
  const [formData, setFormData] = useState({
    inquiryType: "",
    emailId: "",
    emailDomain: "",
    customDomain: "",
    email: "",
    name: "",
    company: "",
    industry: "",
    department: "",
    job: "",
    phone: "",
    message: "",
    agree1: false,
    agree2: false,
    allagree: false,
  });

  const [showDetail1, setShowDetail1] = useState(false);
  const [showDetail2, setShowDetail2] = useState(false);

  const handleChange = (e) => {
    const { name, type, checked } = e.target;
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: type === "checkbox" ? checked : e.target.value,
      };
      updatedData.email =
        updatedData.emailId +
        "@" +
        (updatedData.emailDomain === "직접입력" ||
        updatedData.emailDomain === "Enter Email Domain"
          ? updatedData.customDomain
          : updatedData.emailDomain);
      updatedData.allagree = updatedData.agree1 && updatedData.agree2;
      return updatedData;
    });
  };
  const handleEmailDomainChange = (value) => {
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        emailDomain: value,
      };

      updatedData.email =
        updatedData.emailId +
        "@" +
        (updatedData.emailDomain === "직접입력" ||
        updatedData.emailDomain === "Enter Email Domain"
          ? updatedData.customDomain
          : updatedData.emailDomain);

      return updatedData;
    });
  };

  const handleAllCheckBoxClick = () => {
    const newValue = !formData.allagree;
    setFormData({
      ...formData,
      agree1: newValue,
      agree2: newValue,
      allagree: newValue,
    });
    setShowDetail1(newValue);
    setShowDetail2(newValue);
  };
  const handleAgreeClick = (agreeName) => {
    setFormData((prevData) => {
      const newAgreeValue = !prevData[agreeName];
      const updatedData = {
        ...prevData,
        [agreeName]: newAgreeValue,
      };
      updatedData.allagree = updatedData.agree1 && updatedData.agree2;

      return updatedData;
    });
  };
  const handleDetail1Click = () => {
    setShowDetail1(!showDetail1);
    handleAgreeClick("agree1");
  };
  const handleDetail2Click = () => {
    setShowDetail2(!showDetail2);
    handleAgreeClick("agree2");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.inquiryType) {
      alert("문의 유형을 선택해 주세요.");
      return;
    } else if (!formData.industry) {
      alert("산업군을 선택해 주세요.");
      return;
    } else if (!formData.emailDomain) {
      alert("도메인을 선택해 주세요.");
      return;
    } else if (!formData.agree1) {
      alert("개인정보 수집에 동의해 주세요.");
      return;
    }
    var today = new Date();

    var year = today.getFullYear();
    var month = ("0" + (today.getMonth() + 1)).slice(-2);
    var day = ("0" + today.getDate()).slice(-2);

    var dateString = year + "-" + month + "-" + day;
    var hours = ("0" + today.getHours()).slice(-2);
    var minutes = ("0" + today.getMinutes()).slice(-2);
    var seconds = ("0" + today.getSeconds()).slice(-2);
    var timeString = hours + ":" + minutes + ":" + seconds;

    const formDataToSubmit = {
      문의시간: dateString + " " + timeString,
      문의유형: formData.inquiryType,
      이메일: formData.email,
      성함: formData.name,
      회사: formData.company,
      산업군: formData.industry,
      부서: formData.department,
      직함: formData.job,
      전화번호: formData.phone,
      문의내용: formData.message,
      개인정보수집동의: formData.agree1,
      마케팅정보수신동의: formData.agree2,
    };
    const dataToSubmit = new FormData();
    for (const key in formDataToSubmit) {
      dataToSubmit.append(key, formDataToSubmit[key]);
    }
    fetch(
      "https://script.google.com/macros/s/AKfycbwmcwDRZLPUUWphp_OQ0Q-NBKhP3jGTzYgjNjNr6GkpCIpCdqGssWNyXoT3xQsMj1S4tA/exec",
      {
        method: "POST",
        body: dataToSubmit,
      }
    ).then((response) => {
      if (response.ok) {
        alert(t("ResearchForm.submitSuccess"));
      } else {
        alert(t("ResearchForm.submitError"));
      }
    });
  };
  return (
    <FormDiv>
      <form id="submit-to-google-sheet" onSubmit={handleSubmit}>
        <FormBox>
          <CustomSelect
            label={t("ResearchForm.inquiryType")}
            options={[
              t("ResearchForm.inquiryTypeOptions.product"),
              t("ResearchForm.inquiryTypeOptions.partnership"),
              t("ResearchForm.inquiryTypeOptions.other"),
            ]}
            onChange={(value) =>
              setFormData({ ...formData, inquiryType: value })
            }
          />
        </FormBox>

        <FormBox>
          <Label>
            {t("ResearchForm.name")}
            <LabelSpan> *</LabelSpan>
          </Label>
          <InputBox>
            <Input type="text" name="name" onChange={handleChange} required />
          </InputBox>
        </FormBox>

        <FormBox>
          <Label>
            {t("ResearchForm.company")}
            <LabelSpan> *</LabelSpan>
          </Label>
          <InputBox>
            <Input
              type="text"
              name="company"
              onChange={handleChange}
              required
            />
          </InputBox>
        </FormBox>

        <FormBox>
          <CustomSelect
            label={t("ResearchForm.industry")}
            options={[
              t("ResearchForm.industryOptions.finance"),
              t("ResearchForm.industryOptions.manufacturing"),
              t("ResearchForm.industryOptions.retail"),
              t("ResearchForm.industryOptions.software"),
              t("ResearchForm.industryOptions.government"),
              t("ResearchForm.industryOptions.media"),
              t("ResearchForm.industryOptions.logistics"),
              t("ResearchForm.industryOptions.healthcare"),
              t("ResearchForm.industryOptions.marketing"),
              t("ResearchForm.industryOptions.other"),
            ]}
            onChange={(value) => setFormData({ ...formData, industry: value })}
          />
        </FormBox>

        <FormBox>
          <Label>
            {t("ResearchForm.department")}
            <LabelSpan> *</LabelSpan>
          </Label>
          <InputBox>
            <Input
              type="text"
              name="department"
              onChange={handleChange}
              required
            />
          </InputBox>
        </FormBox>

        <FormBox>
          <Label>
            {t("ResearchForm.job")}
            <LabelSpan> *</LabelSpan>
          </Label>
          <InputBox>
            <Input type="text" name="job" onChange={handleChange} required />
          </InputBox>
        </FormBox>

        <FormBox>
          <Label>
            {t("ResearchForm.phone")}
            <LabelSpan> *</LabelSpan>
          </Label>
          <InputBox>
            <Input type="text" name="phone" onChange={handleChange} required />
          </InputBox>
        </FormBox>

        <Label>
          {t("ResearchForm.email")}
          <LabelSpan> *</LabelSpan>
        </Label>
        <EmailBox>
          <EmailInputBox>
            <EmailInput
              type="text"
              name="emailId"
              placeholder={t("ResearchForm.emailId")}
              onChange={handleChange}
              required
            />
          </EmailInputBox>
          <EmailDomainSignBox>
            <EmailSpan> @ </EmailSpan>
          </EmailDomainSignBox>
          <CustomSelectBox>
            <CustomSelect
              name="emailDomain"
              options={[
                "naver.com",
                "google.com",
                "daum.net",
                t("ResearchForm.customDomain"),
              ]}
              onChange={handleEmailDomainChange}
              required
            />
          </CustomSelectBox>
        </EmailBox>

        {(formData.emailDomain === "직접입력" ||
          formData.emailDomain === "Enter Email Domain") && (
          <InputBox>
            <Input
              type="text"
              name="customDomain"
              placeholder={t("ResearchForm.customEmail")}
              onChange={handleChange}
              required
            />
          </InputBox>
        )}

        <TextAreaBox>
          <Label>
            {t("ResearchForm.inquiryDetails")}
            <LabelSpan> *</LabelSpan>
          </Label>
          <TextArea
            name="message"
            rows="5"
            onChange={handleChange}
            required
            maxLength={1000}
          />
          <CharCount>({formData.message.length}/1,000)</CharCount>
        </TextAreaBox>
        <CheckBoxSubject>
          {t("ResearchForm.privacyPolicyAndMarketing")}
        </CheckBoxSubject>
        <CheckBoxWrapper>
          <CheckBoxInner>
            <CheckBoxBox>
              <CheckBoxBoxInner>
                <Checkbox
                  type="checkbox"
                  name="allagree"
                  onChange={handleAllCheckBoxClick}
                  checked={formData.allagree}
                />
                <CustomCheckbox
                  checked={formData.allagree}
                  onClick={handleAllCheckBoxClick}
                ></CustomCheckbox>
                <CheckBoxLabel onClick={handleAllCheckBoxClick}>
                  {t("ResearchForm.allagree")}
                </CheckBoxLabel>
              </CheckBoxBoxInner>
            </CheckBoxBox>
            <CheckBoxBox>
              <CheckBoxBoxInner>
                <Checkbox
                  type="checkbox"
                  name="agree1"
                  onChange={handleChange}
                  checked={formData.agree1}
                />
                <CustomCheckbox
                  onClick={handleDetail1Click}
                  checked={formData.agree1}
                ></CustomCheckbox>
                <CheckBoxContentInner>
                  <CheckBoxLabel onClick={handleDetail1Click}>
                    <span
                      style={{
                        color: "#e07b27",
                        fontFamily: "Pretendard-Medium",
                      }}
                    >
                      [{i18n.language === "ko" ? "필수" : "Required"}]
                    </span>
                    {t("ResearchForm.agree1")}
                  </CheckBoxLabel>
                </CheckBoxContentInner>
              </CheckBoxBoxInner>
              {showDetail1 ? <UpArrow /> : <DownArrow />}
            </CheckBoxBox>
            <Detail1 $show={showDetail1}>
              {PrivacyPolicyData}
              <Detail1LastContent>
                {t("ResearchForm.agree1Last")}
              </Detail1LastContent>
            </Detail1>
            <CheckBoxBox>
              <CheckBoxBoxInner>
                <Checkbox
                  type="checkbox"
                  name="agree2"
                  onChange={handleChange}
                  checked={formData.agree2}
                />
                <CustomCheckbox
                  onClick={handleDetail2Click}
                  checked={formData.agree2}
                ></CustomCheckbox>
                <CheckBoxContentInner>
                  <CheckBoxLabel onClick={handleDetail2Click}>
                    {t("ResearchForm.agree2")}
                  </CheckBoxLabel>
                </CheckBoxContentInner>
              </CheckBoxBoxInner>
              {showDetail2 ? <UpArrow /> : <DownArrow />}
            </CheckBoxBox>
            <Detail2 $show={showDetail2}>
              {MarketingData}
              <Detail2LastContent>
                {t("ResearchForm.agree1Last")}
              </Detail2LastContent>
            </Detail2>
          </CheckBoxInner>
        </CheckBoxWrapper>

        <Button type="submit">{t("ResearchForm.submit")}</Button>
      </form>
    </FormDiv>
  );
};

export default ResearchForm;
