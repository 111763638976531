import styled from "styled-components";
import HistoryModal from "../../components/modal/HistoryModal";

const Container = styled.section`
  width: 100%;
  height: 100%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: -1;
`;

const VideoBox = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

function Video({ src, setSrc }) {
  return (
    <>
      <Container>
        <VideoBox>
          <video autoPlay muted>
            <source
              src={`${process.env.PUBLIC_URL}/assets/video/vision.mp4`}
              type="video/mp4"
              muted
              playsInline
            />
          </video>
        </VideoBox>
      </Container>
      {src && <HistoryModal imgSrc={src} setSrc={setSrc} />}
    </>
  );
}

export default Video;
