import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Container = styled.section`
  width: 100%;
  height: 100%;
  background-color: #000;
  color: #fff;
`;

const Detail1Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 6rem;
  opacity: ${(props) => (props.$isVisible ? 1 : 0)};
  transform: ${(props) =>
    props.$isVisible ? "translateY(0)" : "translateY(80px)"};
  transition: opacity 2s ease 0.3s, transform 2s ease 0.3s;
  @media screen and (max-width: 1024px) {
    height: 100%;
  }
  @media screen and (max-width: 430px) {
    padding: 5rem 2rem;
  }
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 3rem;
  word-break: keep-all;
  @media screen and (max-width: 430px) {
    font-size: 2rem;
  }
`;

const Detail1 = styled.span`
  font-size: 1.3rem;
  white-space: pre-wrap;
  line-height: 1.4;
  word-break: keep-all;
  @media screen and (max-width: 430px) {
    font-size: 1rem;
  }
`;

const Detail2Box = styled.div`
  display: grid;
  grid-template-columns: 4fr 4fr;
  @media screen and (max-width: 1024px) {
    display: block;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1)),
      url(${process.env.PUBLIC_URL}/assets/images/기업정보.png) no-repeat center
        center;
    background-size: cover;
  }
`;

const Detail2Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 2rem 6rem;
  opacity: ${(props) => (props.$isVisible ? 1 : 0)};
  transition: opacity 2s ease 0.3s;
  @media (min-width: 1025px) and (max-width: 1450px) {
    height: 155vh;
  }
  @media screen and (max-width: 1024px) {
    color: #fff;
    height: 100%;
  }
  @media screen and (max-width: 430px) {
    padding: 5rem 2rem;
  }
`;

const Detail2 = styled.span`
  font-size: 1.2rem;
  white-space: pre-wrap;
  line-height: 1.4;
  word-break: keep-all;
  @media screen and (max-width: 430px) {
    font-size: 1rem;
  }
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

function Description() {
  const { t } = useTranslation(); // 번역 훅 추가
  const elementsRef = useRef({});
  const [visibleElements, setVisibleElements] = useState({
    detail1: false,
    detail2: false,
  });
  const setRef = useCallback((node) => {
    if (node !== null) {
      const targetId = node.getAttribute("data-id");
      elementsRef.current[targetId] = node;
    }
  }, []);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const targetId = entry.target.getAttribute("data-id");
            setVisibleElements((prev) => ({
              ...prev,
              [targetId]: true,
            }));
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    const elements = Object.values(elementsRef.current);
    elements.forEach((el) => {
      if (el) observer.observe(el);
    });

    return () => {
      elements.forEach((el) => {
        if (el) observer.unobserve(el);
      });
    };
  }, []);
  return (
    <Container>
      <Detail1Box
        ref={setRef}
        data-id="detail1"
        $isVisible={visibleElements.detail1}
      >
        <Title>{t("Embracing a New Era")}</Title>
        <Detail1>
          {t(
            "블록체인 기술의 도입으로 금융의 새로운 시대가 열리고 있습니다..."
          )}
        </Detail1>
      </Detail1Box>
      <Detail2Box>
        <Img src={`${process.env.PUBLIC_URL}/assets/images/기업정보.png`} />
        <Detail2Content
          ref={setRef}
          data-id="detail2"
          $isVisible={visibleElements.detail2}
        >
          <Title>{t("The unique benefits of ReturnPlus")}</Title>
          <Detail2>
            {t("아직 존재하지 않는 수조 달러 규모의 시장을 선점하기 위해...")}
          </Detail2>
        </Detail2Content>
      </Detail2Box>
    </Container>
  );
}
export default Description;
