import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

const SliderContainer = styled.div`
  position: relative;
  margin-top: 2rem;
  width: 100%;

  @media screen and (max-width: 430px) {
    margin-left: 0.6rem;
  }
`;

const SliderWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const SliderContent = styled.ul`
  display: flex;
  gap: 1rem;
  transition: transform 0.5s ease-in-out;
  transform: translateX(-${(props) => props.translateX}px);
`;

const SlideItem = styled.li`
  flex: 0 0 ${(props) => props.slideWidth}px;
  height: 22rem;
  border: 1px solid #e5e5e5;
  border-radius: 0.3rem;
  overflow: hidden;

  @media screen and (max-width: 430px) {
    flex: 0 0 224px;
    height: 16rem;
  }
`;

const SlideLink = styled(Link)``;

const SlideImg = styled.img`
  width: 100%;
  height: 50%;
  object-fit: cover;
`;

const SlideText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem;
  line-height: 1.2;
  margin-top: 1rem;

  @media screen and (max-width: 430px) {
    margin-top: 0;
  }
`;

const SlideTitle = styled.span`
  font-size: 1.3rem;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 430px) {
    font-size: 1.1rem;
  }
`;

const SlideContent = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 430px) {
    font-size: 0.8rem;
  }
`;

const NavButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  ${(props) => (props.left ? "left: -4rem;" : "right: -4rem;")}
  transform: translateY(-50%);
  background: transparent;
  border: 1px solid #e5e5e5;
  font-size: 1.4rem;
  padding: 0.4rem;
  border-radius: 9999px;
  cursor: ${(props) =>
    props.isDisabled
      ? "not-allowed"
      : props.isTransitioning
      ? "not-allowed"
      : "pointer"};
  z-index: 10;
  transition: background 0.3s, opacity 0.3s;
  opacity: ${(props) =>
    props.isDisabled ? "0.5" : props.isTransitioning ? "0.5" : "1"};
  visibility: ${(props) => (props.isDisabled ? "hidden" : "visible")};

  &:hover {
    background: ${(props) =>
      props.isDisabled ? "none" : "rgba(0, 0, 0, 0.3)"};
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

function NewsDetailSlider({ newsList }) {
  const { t } = useTranslation();
  // 슬라이더 상태
  const [currentIndex, setCurrentIndex] = useState(0);
  const [sliderItems, setSliderItems] = useState([]);
  const [slideWidth, setSlideWidth] = useState(320); // 기본값 320px

  // 전환 중인지 여부
  const [isTransitioning, setIsTransitioning] = useState(false);

  // 슬라이드 간 간격(px)
  const gap = 16; // CSS의 gap: 1rem (16px)

  // 화면 너비 추적
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

  // 터치 이벤트 상태
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);

  // 슬라이더 아이템 설정
  useEffect(() => {
    if (newsList.length > 0) {
      setSliderItems(newsList);
      setCurrentIndex(0);
    }
  }, [newsList]);

  // 화면 크기 변경 시 상태 업데이트
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 430) {
        setSlideWidth(224);
        setCurrentIndex(0); // 슬라이드 인덱스 초기화
      } else {
        setSlideWidth(320);
        setCurrentIndex(0); // 슬라이드 인덱스 초기화
      }

      setIsMobile(width <= 1024);
    };

    handleResize(); // 초기 설정
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // 현재 슬라이드의 translateX 값 계산
  const translateX = (slideWidth + gap) * currentIndex;

  // 슬라이드 전환 종료 핸들러
  const handleTransitionEnd = () => {
    setIsTransitioning(false);
  };

  // 자동 슬라이드 기능
  const autoSlideTimer = useRef(null);

  const startAutoSlide = () => {
    if (autoSlideTimer.current) return;
    autoSlideTimer.current = setInterval(() => {
      handleNext();
    }, 2000);
  };

  const stopAutoSlide = () => {
    if (autoSlideTimer.current) {
      clearInterval(autoSlideTimer.current);
      autoSlideTimer.current = null;
    }
  };

  // 자동 슬라이드 시작 및 정지
  useEffect(() => {
    if (sliderItems.length > 1) {
      // 최소 2개의 슬라이드가 있을 때 자동 슬라이드 시작
      startAutoSlide();
    }
    return () => stopAutoSlide();
  }, [sliderItems]);

  // 마우스 호버 핸들러
  const handleMouseEnter = () => {
    stopAutoSlide();
  };

  const handleMouseLeave = () => {
    startAutoSlide();
  };

  // 슬라이드 이동 함수
  const handleNext = () => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setCurrentIndex((prevIndex) => {
      if (prevIndex >= sliderItems.length - 1) {
        return 0; // 마지막 슬라이드에서 처음으로 이동
      } else {
        return prevIndex + 1;
      }
    });
    resetAutoSlide();
  };

  const handlePrev = () => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setCurrentIndex((prevIndex) => {
      if (prevIndex === 0) {
        return sliderItems.length - 1; // 처음 슬라이드에서 마지막으로 이동
      } else {
        return prevIndex - 1;
      }
    });
    resetAutoSlide();
  };

  // 자동 슬라이드 리셋
  const resetAutoSlide = () => {
    stopAutoSlide();
    startAutoSlide();
  };

  // 터치 이벤트 핸들러
  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    touchEndX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    const deltaX = touchStartX.current - touchEndX.current;
    const threshold = 50; // 스와이프 감지 임계값

    if (deltaX > threshold) {
      // 왼쪽으로 스와이프
      handleNext();
    } else if (deltaX < -threshold) {
      // 오른쪽으로 스와이프
      handlePrev();
    }

    // 초기화
    touchStartX.current = 0;
    touchEndX.current = 0;
  };

  return (
    <SliderContainer
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onTouchStart={isMobile ? handleTouchStart : null}
      onTouchMove={isMobile ? handleTouchMove : null}
      onTouchEnd={isMobile ? handleTouchEnd : null}
    >
      {!isMobile && (
        <NavButton
          left
          onClick={handlePrev}
          aria-label="Previous Slide"
          isTransitioning={isTransitioning}
          isDisabled={isTransitioning}
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/arrow_left.svg`}
            alt="Arrow Left"
          />
        </NavButton>
      )}
      <SliderWrapper>
        <SliderContent
          translateX={translateX}
          onTransitionEnd={handleTransitionEnd}
        >
          {sliderItems.map((item, index) => (
            <SlideItem key={index} slideWidth={slideWidth}>
              <SlideLink to={`/news/${item._id}`} state={{ state: newsList }}>
                <SlideImg src={item.image} alt={`${item.title} 이미지`} />
                <SlideText>
                  <SlideTitle>
                    {item ? t(`news_${item._id}_title`) : ""}
                  </SlideTitle>
                  <SlideContent>
                    {" "}
                    {item ? t(`news_${item._id}_content`) : ""}
                  </SlideContent>
                </SlideText>
              </SlideLink>
            </SlideItem>
          ))}
        </SliderContent>
      </SliderWrapper>
      {!isMobile && (
        <NavButton
          onClick={handleNext}
          aria-label="Next Slide"
          isTransitioning={isTransitioning}
          isDisabled={isTransitioning}
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/arrow_right.svg`}
            alt="Arrow Right"
          />
        </NavButton>
      )}
    </SliderContainer>
  );
}

export default React.memo(NewsDetailSlider);
