import React, { useState } from "react";
import styled from "styled-components";
import { PrivacyPolicyData } from "./PrivacyPolicyData";
import { useTranslation } from "react-i18next";
import CustomSelect from "../Select/CustomSelect";
import { MarketingData } from "../EmailForm/MarketingData";

const FormDiv = styled.div`
  margin: 2rem auto;
`;

const FormBox = styled.div`
  margin-bottom: 1rem;
`;

const Label = styled.label`
  display: block;
  font-family: "Pretendard-Light";
  margin-bottom: 0.5rem;
`;
const LabelSpan = styled.span`
  font-family: "Pretendard-Medium";
  color: #e07b27;
`;
const InputBox = styled.div`
  border-bottom: 0.1rem solid #050303;
`;
const Input = styled.input`
  width: 100%;
  height: 2.625rem;
  padding: 0.6rem;
  border: none;
`;

const EmailBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const EmailInputBox = styled.div`
  width: 55%;
  border-bottom: 0.1rem solid #050303;
`;
const EmailInput = styled.input`
  width: 55%;
  height: 2.625rem;
  padding: 0.6rem;
  border: none;
`;

const EmailDomainSignBox = styled.div`
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EmailSpan = styled.span`
  font-family: "Pretendard-SemiBold";
  font-size: 1.5rem;
  @media (max-width: 480px) {
    font-size: 1rem;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    font-size: 1.4rem;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    font-size: 1.8rem;
  }
`;
const CustomSelectBox = styled.div`
  width: 40%;
`;
const Button = styled.button`
  margin-top: 1rem;
  margin-bottom: 3rem;
  padding: 1rem;
  color: #fff;
  background-color: #050303;
  font-family: "Pretendard-SemiBold";
  font-size: 1rem;
  border: 1px solid #050303;
  cursor: pointer;
  width: 100%;
  &:hover {
    opacity: 0.7;
  }
`;

const CheckBoxWrapper = styled.div`
  margin-top: 2rem;
  display: block;
  height: 100%;
`;
const CheckBoxSubject = styled.p`
  font-family: "Pretendard-Medium";
  font-size: 1.056rem;
  color: #050303;
  margin-bottom: 0.2rem;
`;
const Checkbox = styled.input`
  display: none;
`;

const CustomCheckbox = styled.div`
  width: 1.138rem;
  height: 1.138rem;
  border: 0.1rem solid #050303;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  background: #fff;
  position: relative;
  cursor: pointer;
  &::after {
    content: "";
    display: ${(props) => (props.checked ? "block" : "none")};
    width: 0.2rem;
    height: 0.5rem;
    border: solid #050303;
    border-width: 0 0.12rem 0.12rem 0;
    transform: rotate(45deg);
    position: absolute;
    left: 5px;
    top: 2px;
  }
`;

const CheckBoxInner = styled.div`
  width: 100%;
  height: 100%;
  display: block;
`;
const CheckBoxBox = styled.div`
  font-family: "Pretendard-Medium";
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.1rem solid #050303;
  padding: 0.5rem;
  font-size: 0.89rem;
  &:nth-child(2) {
    border-bottom: none;
    padding-left: 3rem;
  }
  &:nth-child(4) {
    border-top: 0.1rem solid #050303;
    padding-left: 3rem;
  }
  @media screen and (max-width: 481px) {
    &:nth-child(2) {
      padding-left: 2rem;
    }
    &:nth-child(4) {
      padding-left: 2rem;
    }
  }
`;
const CheckBoxBoxInner = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;
const CheckBoxLabel = styled.label`
  display: block;
  cursor: pointer;
  color: #050303;
  line-height: 1.1rem;
`;

const Detail1 = styled.div`
  display: ${(props) => (props.$show ? "block" : "none")};
  background-color: #e9ecef;
  height: 10rem;
  padding-left: 5rem;
  font-family: "Pretendard-Regular";
  font-size: 0.778rem;
  box-sizing: border-box;
  border-top: 0.1rem solid #050303;
  white-space: pre-wrap;
  line-height: 1rem;
  color: #050303;
  background-color: #fff;
  @media screen and (max-width: 481px) {
    padding-left: 3rem;
    height: 16rem;
  }
`;

const Detail1LastContent = styled.p`
  font-family: "Pretendard-SemiBold";
  font-size: 0.778rem;
  color: #e07b27;
`;

const Detail2 = styled.div`
  display: ${(props) => (props.$show ? "block" : "none")};
  background-color: #e9ecef;
  height: 10rem;
  padding-left: 5rem;
  font-family: "Pretendard-Regular";
  font-size: 0.778rem;
  box-sizing: border-box;
  white-space: pre-wrap;
  line-height: 1rem;
  color: #050303;
  background-color: #fff;
  padding-bottom: 0.778rem;
  border-bottom: 0.1rem solid #050303;
  @media screen and (max-width: 481px) {
    height: 14rem;
    padding-left: 3rem;
  }
`;

const Detail2LastContent = styled.p`
  font-family: "Pretendard-SemiBold";
  font-size: 0.778rem;
  color: #e07b27;
`;
const DownArrow = styled.div`
  width: 0.7rem;
  height: 0.7rem;
  border: solid #050303;
  margin-bottom: 0.35rem;
  margin-right: 0.5rem;
  border-width: 0 0.1rem 0.1rem 0;
  transform: rotate(45deg);
`;
const UpArrow = styled.div`
  width: 0.7rem;
  height: 0.7rem;
  border: solid #050303;
  margin-top: 0.35rem;
  margin-right: 0.5rem;
  border-width: 0 0.1rem 0.1rem 0;
  transform: rotate(225deg);
`;
const EmailForm = () => {
  const { t, i18n } = useTranslation();
  const [formData, setFormData] = useState({
    inquiryType: "",
    email: "",
    emailId: "",
    emailDomain: "",
    customDomain: "",
    name: "",
    company: "",
    industry: "",
    department: "",
    job: "",
    phone: "",
    message: "",
    agree1: false,
    agree2: false,
  });

  const [showDetail1, setShowDetail1] = useState(false);
  const [showDetail2, setShowDetail2] = useState(false);

  const handleChange = (e) => {
    const { name, type, checked } = e.target;
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: type === "checkbox" ? checked : e.target.value,
      };
      updatedData.email =
        updatedData.emailId +
        "@" +
        (updatedData.emailDomain === "직접입력" ||
        updatedData.emailDomain === "Enter Email Domain"
          ? updatedData.customDomain
          : updatedData.emailDomain);
      updatedData.allagree = updatedData.agree1 && updatedData.agree2;
      return updatedData;
    });
  };
  const handleEmailDomainChange = (value) => {
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        emailDomain: value,
      };

      updatedData.email =
        updatedData.emailId +
        "@" +
        (updatedData.emailDomain === "직접입력" ||
        updatedData.emailDomain === "Enter Email Domain"
          ? updatedData.customDomain
          : updatedData.emailDomain);

      return updatedData;
    });
  };
  const handleAllCheckBoxClick = () => {
    const newValue = !formData.allagree;
    setFormData({
      ...formData,
      agree1: newValue,
      agree2: newValue,
      allagree: newValue,
    });
    setShowDetail1(newValue);
    setShowDetail2(newValue);
  };
  const handleAgreeClick = (agreeName) => {
    setFormData((prevData) => {
      const newAgreeValue = !prevData[agreeName];
      const updatedData = {
        ...prevData,
        [agreeName]: newAgreeValue,
      };
      updatedData.allagree = updatedData.agree1 && updatedData.agree2;

      return updatedData;
    });
  };
  const handleDetail1Click = () => {
    setShowDetail1(!showDetail1);
    handleAgreeClick("agree1");
  };
  const handleDetail2Click = () => {
    setShowDetail2(!showDetail2);
    handleAgreeClick("agree2");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.industry) {
      alert("산업군을 선택해 주세요.");
      return;
    } else if (!formData.emailDomain) {
      alert("도메인을 선택해 주세요.");
      return;
    } else if (!formData.agree1) {
      alert("개인정보 수집 및 이용에 동의해주세요.");
      return;
    }
    var today = new Date();

    var year = today.getFullYear();
    var month = ("0" + (today.getMonth() + 1)).slice(-2);
    var day = ("0" + today.getDate()).slice(-2);

    var dateString = year + "-" + month + "-" + day;
    var hours = ("0" + today.getHours()).slice(-2);
    var minutes = ("0" + today.getMinutes()).slice(-2);
    var seconds = ("0" + today.getSeconds()).slice(-2);
    var timeString = hours + ":" + minutes + ":" + seconds;

    const formDataToSubmit = {
      다운로드시간: dateString + " " + timeString,
      이메일: formData.email,
      성함: formData.name,
      회사: formData.company,
      산업군: formData.industry,
      부서: formData.department,
      직함: formData.job,
      전화번호: formData.phone,
      개인정보수집동의: formData.agree1,
      마케팅정보수신동의: formData.agree2,
    };
    const dataToSubmit = new FormData();
    for (const key in formDataToSubmit) {
      dataToSubmit.append(key, formDataToSubmit[key]);
    }
    fetch(
      "https://script.google.com/macros/s/AKfycbxxJlcgp02jcLDBrRoJNvhw_UqbVvXS5V0taIzb40QR3JxDExaekMQJuHKrBR0SnngDjA/exec",
      {
        method: "POST",
        body: dataToSubmit,
      }
    ).then((response) => {
      if (response.ok) {
        alert(t("EmailForm.successMessage"));
      } else {
        alert(t("EmailForm.browserError"));
      }
    });
    try {
      let fileUrl = `${process.env.PUBLIC_URL}/assets/Return Plus IR Document.docx`;
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = "(주)리턴플러스 IR 자료.docx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      alert(t("EmailForm.browserError"));
    }
  };
  return (
    <FormDiv>
      <form onSubmit={handleSubmit}>
        <FormBox>
          <Label>
            {t("EmailForm.name")}
            <LabelSpan> *</LabelSpan>
          </Label>
          <InputBox>
            <Input type="text" name="name" onChange={handleChange} required />
          </InputBox>
        </FormBox>
        <FormBox>
          <Label>
            {t("EmailForm.company")} <LabelSpan> *</LabelSpan>
          </Label>
          <InputBox>
            <Input
              type="text"
              name="company"
              onChange={handleChange}
              required
            />
          </InputBox>
        </FormBox>
        <FormBox>
          <Label>
            {t("EmailForm.industry")} <LabelSpan> *</LabelSpan>
          </Label>
          <CustomSelect
            options={[
              t("EmailForm.industryOptions.finance"),
              t("EmailForm.industryOptions.construction"),
              t("EmailForm.industryOptions.consumer"),
              t("EmailForm.industryOptions.software"),
              t("EmailForm.industryOptions.government"),
              t("EmailForm.industryOptions.media"),
              t("EmailForm.industryOptions.logistics"),
              t("EmailForm.industryOptions.healthcare"),
              t("EmailForm.industryOptions.marketing"),
              t("EmailForm.industryOptions.other"),
            ]}
            onChange={(value) => setFormData({ ...formData, industry: value })}
            required
          />
        </FormBox>
        <FormBox>
          <Label>
            {t("EmailForm.department")} <LabelSpan> *</LabelSpan>
          </Label>
          <InputBox>
            <Input
              type="text"
              name="department"
              onChange={handleChange}
              required
            />
          </InputBox>
        </FormBox>
        <FormBox>
          <Label>
            {t("EmailForm.jobTitle")} <LabelSpan> *</LabelSpan>
          </Label>
          <InputBox>
            <Input type="text" name="job" onChange={handleChange} required />
          </InputBox>
        </FormBox>
        <FormBox>
          <Label>
            {t("EmailForm.phone")} <LabelSpan> *</LabelSpan>
          </Label>
          <InputBox>
            <Input type="text" name="phone" onChange={handleChange} required />
          </InputBox>
        </FormBox>
        <Label>
          {t("EmailForm.email")} <LabelSpan> *</LabelSpan>
        </Label>
        <EmailBox>
          <EmailInputBox>
            <EmailInput
              type="text"
              name="emailId"
              placeholder={t("EmailForm.emailPlaceholder")}
              onChange={handleChange}
              required
            />
          </EmailInputBox>
          <EmailDomainSignBox>
            <EmailSpan> @ </EmailSpan>
          </EmailDomainSignBox>
          <CustomSelectBox>
            <CustomSelect
              options={[
                "naver.com",
                "google.com",
                "daum.net",
                t("EmailForm.customDomain"),
              ]}
              onChange={handleEmailDomainChange}
              required
            />
          </CustomSelectBox>
        </EmailBox>
        {(formData.emailDomain === "직접입력" ||
          formData.emailDomain === "Enter Your Email Domain") && (
          <InputBox>
            <Input
              type="text"
              name="customDomain"
              placeholder={t("EmailForm.customDomainPlaceholder")}
              onChange={handleChange}
              required
            />
          </InputBox>
        )}
        <CheckBoxWrapper>
          <CheckBoxSubject>
            {t("ResearchForm.privacyPolicyAndMarketing")}
          </CheckBoxSubject>
          <CheckBoxInner>
            <CheckBoxBox>
              <CheckBoxBoxInner>
                <Checkbox
                  type="checkbox"
                  name="allagree"
                  onChange={handleAllCheckBoxClick}
                  checked={formData.allagree}
                />
                <CustomCheckbox
                  checked={formData.allagree}
                  onClick={handleAllCheckBoxClick}
                ></CustomCheckbox>
                <CheckBoxLabel onClick={handleAllCheckBoxClick}>
                  {t("ResearchForm.allagree")}
                </CheckBoxLabel>
              </CheckBoxBoxInner>
            </CheckBoxBox>
            <CheckBoxBox>
              <CheckBoxBoxInner>
                <Checkbox
                  type="checkbox"
                  name="agree1"
                  onChange={handleChange}
                  checked={formData.agree1}
                />
                <CustomCheckbox
                  onClick={handleDetail1Click}
                  checked={formData.agree1}
                ></CustomCheckbox>
                <CheckBoxLabel onClick={handleDetail1Click}>
                  <span
                    style={{
                      color: "#e07b27",
                      fontFamily: "Pretendard-Medium",
                    }}
                  >
                    [{i18n.language === "ko" ? "필수" : "Required"}]
                  </span>
                  {t("ResearchForm.agree1")}
                </CheckBoxLabel>
              </CheckBoxBoxInner>
              {showDetail1 ? <UpArrow /> : <DownArrow />}
            </CheckBoxBox>
            <Detail1 $show={showDetail1}>
              {PrivacyPolicyData}
              <Detail1LastContent>
                {t("ResearchForm.agree1Last")}
              </Detail1LastContent>
            </Detail1>
            <CheckBoxBox>
              <CheckBoxBoxInner>
                <Checkbox
                  type="checkbox"
                  name="agree2"
                  onChange={handleChange}
                  checked={formData.agree2}
                />
                <CustomCheckbox
                  onClick={handleDetail2Click}
                  checked={formData.agree2}
                ></CustomCheckbox>
                <CheckBoxLabel onClick={handleDetail2Click}>
                  {t("ResearchForm.agree2")}
                </CheckBoxLabel>
              </CheckBoxBoxInner>
              {showDetail2 ? <UpArrow /> : <DownArrow />}
            </CheckBoxBox>
            <Detail2 $show={showDetail2}>
              {MarketingData}
              <Detail2LastContent>
                {t("ResearchForm.agree1Last")}
              </Detail2LastContent>
            </Detail2>
          </CheckBoxInner>
        </CheckBoxWrapper>
        <Button type="submit">{t("EmailForm.downloadCompanyProfile")}</Button>
      </form>
    </FormDiv>
  );
};

export default EmailForm;
