import { useEffect, useRef, useState } from "react";
import useInterval from "../../util/useInterval";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Slider = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const SlidesContainer = styled.div`
  display: flex;
  transition: transform 0.5s ease;
`;

const SlideItem = styled.div`
  position: relative;
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 2rem;
  width: 100%;
  height: 100vh;
  padding: 10rem;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${(props) => props.bgImg}) no-repeat center center;
  background-size: cover;
  color: white;
  font-size: 40px;
  @media screen and (max-width: 1024px) {
    height: 80vh;
  }
  @media screen and (max-width: 430px) {
    height: 60vh;
    padding: 5rem 2rem 10rem 2rem;
  }
`;

const SlideTitle = styled.span`
  font-weight: bold;
  font-size: 3rem;
  white-space: pre-wrap;
  line-height: 1.2;
  word-break: keep-all;
  @media screen and (max-width: 430px) {
    font-size: 2rem;
  }
`;

const SlideContent = styled.span`
  font-size: 1.5rem;
  white-space: pre-wrap;
  line-height: 1.4;
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @media screen and (max-width: 430px) {
    font-size: 1rem;
  }
`;

const GoNewsBtn = styled(Link)`
  padding: 0.5rem 2rem;
  font-size: 1.3rem;
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
  margin-top: 2rem;
  @media screen and (max-width: 430px) {
    font-size: 1rem;
    margin-top: 0;
  }
`;

const Pagination = styled.ul`
  display: flex;
  gap: 1rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 3rem;
  list-style: none;
  @media screen and (max-width: 430px) {
    bottom: 2rem;
    gap: 0.5rem;
  }
`;

const PaginationItem = styled.li`
  position: relative;
  width: 10vw;
  height: 4px;
  background-color: #6b6b6d;
  border-radius: 20rem;
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  margin: 0 5px;
  cursor: pointer;
  overflow: hidden;

  @media screen and (max-width: 430px) {
    width: 15vw;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #fff;
    transform-origin: left;
    transform: scaleX(0);
    ${({ active }) =>
      active &&
      `
      transform: scaleX(1);
      transition: transform 5s linear;
    `}
  }
`;

const SlideBtn = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 18px;
  border-radius: 50%;

  &:first-of-type {
    left: 2rem;
    img {
      transform: rotate(180deg);
    }
  }

  &:last-of-type {
    right: 2rem;
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;
const SlideBtnImg = styled.img`
  width: 3rem;
  height: 3rem;
`;

function NewsRoomSlider({ sliceList, sendList }) {
  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(1);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [intervalTrigger, setIntervalTrigger] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const totalSlides = 3;
  const slideContainerRef = useRef(null);
  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  const handleNextSlide = () => {
    goToSlide(currentSlide + 1);
    setIntervalTrigger((prev) => prev + 1);
  };

  const handlePrevSlide = () => {
    goToSlide(currentSlide - 1);
    setIntervalTrigger((prev) => prev + 1);
  };

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  useEffect(() => {
    (currentSlide > 4 || currentSlide < 0) && setCurrentSlide(2);
  }, [currentSlide]);

  useInterval(() => handleNextSlide(), 5000, [intervalTrigger]);

  useEffect(() => {
    const slideContainer = slideContainerRef.current;
    if (!slideContainer) return;

    // 슬라이드 이동 애니메이션 적용
    slideContainer.style.transition = "transform 0.5s ease-in-out";
    slideContainer.style.transform = `translateX(-${currentSlide * 100}%)`;

    const handleTransitionEnd = () => {
      if (currentSlide === totalSlides + 1) {
        // 상태 업데이트와 스타일 변경을 다음 렌더링 사이클로 미룸
        requestAnimationFrame(() => {
          slideContainer.style.transition = "none";
          setCurrentSlide(1);
          slideContainer.style.transform = `translateX(-100%)`;
          // 다음 렌더링 사이클에서 트랜지션 재적용
          requestAnimationFrame(() => {
            slideContainer.style.transition = "transform 0.5s ease-in-out";
          });
        });
      } else if (currentSlide === 0) {
        requestAnimationFrame(() => {
          slideContainer.style.transition = "none";
          setCurrentSlide(totalSlides);
          slideContainer.style.transform = `translateX(-${totalSlides * 100}%)`;
          requestAnimationFrame(() => {
            slideContainer.style.transition = "transform 0.5s ease-in-out";
          });
        });
      }
    };

    slideContainer.addEventListener("transitionend", handleTransitionEnd);

    return () => {
      slideContainer.removeEventListener("transitionend", handleTransitionEnd);
    };
  }, [currentSlide, totalSlides]);

  const getSlideBgImg = (index) => {
    if (windowWidth <= 430) {
      return `${process.env.PUBLIC_URL}/assets/images/slide_m${index}.png`;
    } else if (windowWidth <= 1024) {
      return `${process.env.PUBLIC_URL}/assets/images/slide_t${index}.png`;
    } else {
      return `${process.env.PUBLIC_URL}/assets/images/slide_p${index}.png`;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [windowWidth]);

  useEffect(() => {
    console.log(windowWidth);
  }, [windowWidth]);

  return (
    <Slider>
      <SlidesContainer
        ref={slideContainerRef}
        style={{
          transform: `translateX(-${currentSlide * 100}%)`,
        }}
      >
        {sliceList?.map((data, index) => (
          <SlideItem key={index} bgImg={getSlideBgImg(index)}>
            <SlideTitle>{t(`news_${data._id}_title`)}</SlideTitle>
            <SlideContent>{t(`news_${data._id}_content`)}</SlideContent>
            <GoNewsBtn
              to={`/news/${data._id}`}
              state={{
                state: sendList,
              }}
            >
              {t("기사보러가기")}
            </GoNewsBtn>
          </SlideItem>
        ))}
      </SlidesContainer>
      <SlideBtn onClick={handlePrevSlide}>
        <SlideBtnImg
          src={`${process.env.PUBLIC_URL}/assets/images/arrow.png`}
          style={{ transform: "rotate(180deg)" }}
        />
      </SlideBtn>
      <SlideBtn onClick={handleNextSlide}>
        <SlideBtnImg
          src={`${process.env.PUBLIC_URL}/assets/images/arrow.png`}
        />
      </SlideBtn>
      <Pagination>
        {Array.from({ length: totalSlides }).map((_, index) => {
          const isActive =
            !isFirstRender &&
            (currentSlide === index + 1 ||
              (currentSlide === 0 && index === totalSlides - 1) ||
              (currentSlide === totalSlides + 1 && index === 0));

          return (
            <PaginationItem
              key={index}
              active={isActive}
              onClick={() => {
                goToSlide(index + 1);
                setIntervalTrigger((prev) => prev + 1);
              }}
            />
          );
        })}
      </Pagination>
    </Slider>
  );
}
export default NewsRoomSlider;
