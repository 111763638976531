import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const SelectContainer = styled.div`
  position: relative;
  width: 100%;
`;

const SelectLabel = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.89rem;
  font-family: "Pretendard-Medium";
  margin-bottom: 0.5rem;
  color: #fff;
`;

const SelectLabelSpan = styled.span`
  font-family: "Pretendard-Medium";
  color: #e07b27;
`;
const RequiredIndicator = styled.span`
  color: red;
  font-size: 0.8rem;
`;
const SelectBox = styled.div`
  position: relative;
  cursor: pointer;
  border: 0.1rem solid #ccc;
  background-color: #fff;
  padding: 8px;
  font-size: 0.95rem;
  display: flex;
  height: 2.5rem;
  justify-content: space-between;
  align-items: center;
`;
const DownArrow = styled.div`
  width: 0.7rem;
  height: 0.7rem;
  border: solid #050303;
  margin-bottom: 0.35rem;
  margin-right: 0.5rem;
  border-width: 0 0.1rem 0.1rem 0;
  transform: rotate(45deg);
`;
const UpArrow = styled.div`
  width: 0.7rem;
  height: 0.7rem;
  border: solid #050303;
  margin-top: 0.35rem;
  margin-right: 0.5rem;
  border-width: 0 0.1rem 0.1rem 0;
  transform: rotate(225deg);
`;
const OptionsList = styled.ul`
  position: absolute;
  top: 100%;
  max-height: 9.2rem;
  overflow-y: auto;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  background-color: #fff;
  z-index: 1000;
  padding: 0;
  margin: 0;
  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #050303;
    border-radius: 2rem;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
  }
`;

const Option = styled.li`
  padding: 10px;
  color: #333;
  cursor: pointer;

  &:hover {
    background-color: #e9ecef;
  }

  &.selected {
    background-color: #007bff;
    color: #fff;
  }
`;

const CustomSelect = ({ label, options, onChange, required }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const { t } = useTranslation();

  const toggleOptions = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    onChange(option);
    setIsOpen(false);
  };

  return (
    <SelectContainer>
      {label ? (
        <SelectLabel>
          {label} <SelectLabelSpan> *</SelectLabelSpan>
          {required && <RequiredIndicator>*</RequiredIndicator>}{" "}
        </SelectLabel>
      ) : (
        ""
      )}
      <SelectBox onClick={toggleOptions}>
        {selectedOption || t("ResearchForm.selectOption")}
        {isOpen ? <UpArrow></UpArrow> : <DownArrow />}
      </SelectBox>
      {isOpen && (
        <OptionsList>
          {options.map((option) => (
            <Option key={option} onClick={() => handleOptionClick(option)}>
              {option}
            </Option>
          ))}
        </OptionsList>
      )}
    </SelectContainer>
  );
};

export default CustomSelect;
