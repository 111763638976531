import React from "react";
import styled from "styled-components";
import IRDownload from "../Button/IRDownload";
import { useTranslation } from "react-i18next"; // i18n 추가
import { useLocation, useNavigate } from "react-router-dom";

const Div = styled.footer`
  width: 100%;
  height: 30vh;
  display: flex;
  background-color: black;
`;

const LeftWrapper = styled.div`
  width: 65%;
  padding: 2rem 0 2rem 15rem;
  @media (max-width: 1024px) {
    padding: 2rem 0 2rem 2rem;
  }
`;

const RightWrapper = styled.div`
  width: 35%;
  padding: 2rem 15rem 2rem 0;
  @media (max-width: 1024px) {
    padding: 2rem 2rem 2rem 0;
  }
`;

const LogoBox = styled.div`
  width: 27rem;
  height: auto;
  @media (max-width: 480px) {
    width: 10rem;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    width: 17rem;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    width: 25rem;
  }
`;

const Logo = styled.div`
  width: 15rem;
  height: 2.5rem;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  @media (max-width: 480px) {
    width: 9rem;
    height: 1.5rem;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    width: 13rem;
    height: 2.1rem;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    width: 14rem;
    height: 2.3rem;
  }
`;

const ButtonBox = styled.div`
  padding-top: 3rem;
  height: auto;
`;

const NavBox = styled.div`
  width: 100%;
  padding-top: 1rem;
  height: auto;
`;

const NavInner = styled.div`
  display: flex;
  gap: 2rem;
  font-family: "Pretendard-Regular";
`;

// const NavContent = styled.p`
//   cursor: pointer;
//   color: white;
//   font-size: 1rem;
//   &:hover {
//     opacity: 0.7;
//   }
// `;

const InfoBox = styled.div`
  width: 100%;
  height: 100%;
  float: right;
  text-align: left;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  font-family: "Pretendard-Regular";
  font-weight: 600;
  letter-spacing: 1.2px;
`;

const Link = styled.a`
  text-decoration: none;
  display: block;
  cursor: pointer;
  color: white;
  letter-spacing: 1.2px;
  &:hover {
    opacity: 0.7;
  }
  @media (max-width: 480px) {
    font-size: 0.5rem;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    font-size: 0.7rem;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    font-size: 1rem;
  }
`;

const Address = styled.p`
  font-family: "Pretendard-Regular";
  font-size: 0.7rem;
  margin-top: 2rem;
  color: white;
  @media (max-width: 480px) {
    font-size: 0.3rem;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    font-size: 0.3rem;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    font-size: 0.5rem;
  }
`;

const InnerBox = styled.div`
  margin-top: 4rem;
  text-align: left;
`;

const CopyRight = styled.p`
  font-size: 0.7rem;
  color: #999;
  @media (max-width: 480px) {
    font-size: 0.3rem;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    font-size: 0.3rem;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    font-size: 0.5rem;
  }
`;

function Footer({ setIsOpen, onScrollToSection }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation(); // i18n 훅 사용

  const navHandler = (section) => {
    if (pathname !== "/") {
      navigate("/", { state: { scrollTo: section } });
    } else {
      onScrollToSection(section);
    }
  };
  return (
    <Div>
      <LeftWrapper>
        <LogoBox>
          <Logo src="/assets/HeaderLogo.png" />
        </LogoBox>
        <ButtonBox>
          <IRDownload setIsOpen={setIsOpen} />
        </ButtonBox>
        <NavBox>
          <NavInner>
            <Link href="#">{t("Footer.privacyPolicy")}</Link>
            {/* <NavContent onClick={() => onScrollToSection("research")}>
              {t("Footer.contactUs")}
            </NavContent> */}
          </NavInner>
        </NavBox>
        <Address>{t("Footer.address")}</Address>
      </LeftWrapper>
      <RightWrapper>
        <InfoBox>
          <Link onClick={() => navHandler("service")}>
            {t("Footer.companyInfo")}
          </Link>
          <Link onClick={() => navHandler("research")}>
            {t("Footer.research")}
          </Link>
          <Link onClick={() => navHandler("newsroom")}>
            {t("Footer.newsroom")}
          </Link>
          <InnerBox>
            <CopyRight>{t("Footer.copyright")}</CopyRight>
          </InnerBox>
        </InfoBox>
      </RightWrapper>
    </Div>
  );
}

export default Footer;
