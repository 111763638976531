import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { historyDummyData } from "../../api/dummyData";

const Container = styled.div`
  height: 100vh;
  padding: 10rem 17rem;
  background-color: #fff;
  position: relative;
  @media screen and (max-width: 1400px) {
    padding: 6rem 11rem;
    height: 100%;
  }
  @media screen and (max-width: 430px) {
    padding: 5rem 2rem;
  }
`;
const Title = styled.div`
  font-size: 2.5rem;
  font-weight: bold;
  white-space: pre-wrap;
  @media screen and (max-width: 430px) {
    font-size: 1.8rem;
    line-height: 1.2;
  }
`;
const HistoryTitle = styled(Title)`
  opacity: ${(props) => (props.$isVisible ? 1 : 0)};
  transform: ${(props) =>
    props.$isVisible ? "translateY(0)" : "translateY(80px)"};
  transition: opacity 2s ease 0.3s, transform 2s ease 0.3s;
  @media screen and (max-width: 1024px) {
    margin-bottom: 1rem;
  }
  @media screen and (max-width: 430px) {
    margin-bottom: 1.1rem;
  }
`;

const HistorySubTitle = styled.div`
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.2;
  padding: 2rem 0 1rem 0;
  opacity: ${(props) => (props.$isVisible ? 1 : 0)};
  transform: ${(props) =>
    props.$isVisible ? "translateY(0)" : "translateY(80px)"};
  transition: opacity 2s ease 0.5s, transform 2s ease 0.5s;
  word-break: keep-all;
  @media screen and (max-width: 1024px) {
    margin-bottom: 1rem;
  }
  @media screen and (max-width: 430px) {
    font-size: 1rem;
    padding: 0;
    padding-bottom: 2rem;
  }
`;

const HistoryItemBox = styled.ul`
  border-top: 1px solid #000;
  opacity: ${(props) => (props.$isVisible ? 1 : 0)};
  transform: ${(props) =>
    props.$isVisible ? "translateY(0)" : "translateY(80px)"};
  transition: opacity 2s ease 0.7s, transform 2s ease 0.7s;
`;

const HistoryItem = styled.li`
  border-bottom: 1px solid #000;
  display: grid;
  grid-template-columns: 6fr 5fr;
  padding: 2rem 0;
  @media screen and (max-width: 1024px) {
    grid-template-columns: 5fr 5fr;
  }
  @media screen and (max-width: 430px) {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
`;

const Year = styled.div`
  font-weight: bold;
  font-size: 1.8rem;
  @media screen and (max-width: 430px) {
    font-size: 1.4rem;
  }
`;

const ContentBox = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const Content = styled.li`
  display: flex;
  align-items: end;
  gap: 3rem;
`;

const Time = styled.div`
  color: gray;
`;

const Activity = styled.div`
  font-weight: 500;
  font-size: 1.2rem;
  display: flex;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 20rem;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-height: 1.2;
  }
  @media screen and (max-width: 430px) {
    font-size: 1rem;
  }
`;

const ModalBtn = styled.button`
  padding: 0.1rem 0.3rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const AddImg = styled.img`
  width: 0.8rem;
  height: 0.8rem;
  vertical-align: middle;
`;

function History({ setSrc }) {
  const { t } = useTranslation();
  const [visibleHistoryItems, setVisibleHistoryItems] = useState([]);

  const historyItemsRef = useRef([]);

  const modalHandle = (imgSrc) => {
    setSrc(imgSrc);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = historyItemsRef.current.indexOf(entry.target);
          if (entry.isIntersecting && index !== -1) {
            setVisibleHistoryItems((prev) => [...prev, index]);
          }
        });
      },
      { threshold: 0.3 }
    );

    const historyElements = [...historyItemsRef.current];
    historyElements.forEach((item) => {
      if (item) observer.observe(item);
    });

    return () => {
      historyElements.forEach((item) => {
        if (item) observer.unobserve(item);
      });
    };
  }, []);

  return (
    <Container>
      <HistoryTitle
        $isVisible={visibleHistoryItems.includes(0)}
        ref={(el) => (historyItemsRef.current[0] = el)}
      >
        {t("History")}
      </HistoryTitle>

      <HistorySubTitle
        $isVisible={visibleHistoryItems.includes(1)}
        ref={(el) => (historyItemsRef.current[1] = el)}
      >
        {t("PartnershipSubtitle")}
      </HistorySubTitle>

      <HistoryItemBox
        $isVisible={visibleHistoryItems.includes(2)}
        ref={(el) => (historyItemsRef.current[2] = el)}
      >
        {historyDummyData.map((data, index) => (
          <HistoryItem key={index}>
            <Year>{data.year}</Year>
            <ContentBox>
              {data.activities.map((item, idx) => (
                <Content key={idx}>
                  <Time>
                    {item.time || <div style={{ width: "3.8rem" }}></div>}
                  </Time>
                  <Activity>
                    <span>
                      {t(`historyDummyData.2024.activities.${idx}.activity`)}
                    </span>
                    {item.img && (
                      <ModalBtn onClick={() => modalHandle(item.img)}>
                        <AddImg
                          src={`${process.env.PUBLIC_URL}/assets/images/add.png`}
                          alt="Add"
                        />
                      </ModalBtn>
                    )}
                  </Activity>
                </Content>
              ))}
            </ContentBox>
          </HistoryItem>
        ))}
      </HistoryItemBox>
    </Container>
  );
}

export default History;