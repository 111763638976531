export const postListData = [
  {
    to: "returnplus",
    title: "블록체인 기술이 일상화된 미래 | 리턴플러스 CEO 인터뷰1",
    content: [
      {
        c: `RETURN PLUS의 로고는 파란색으로 데이터의 투명성과 보안을 바탕으로 한 신뢰를, 주황색으로 성장과 에너지를 상징합니다. 
        우리 'RETURN PLUS'의 핵심 개념은 "추가적인 가치", "성장", "금융, 자산 관리 등 다양한 산업에 적용될 수 있는 확장성"입니다.
        이를 나타내는 상징적인 모양인 플러스(+)의 형태의 곡선 디자인은 유연성을 강조합니다.
        이처럼 RETURN PLUS는 신뢰를 바탕으로 성장과 확장을 추구하며, 다양한 산업에 유연하게 적용될 수 있는 혁신적인 솔루션을 제공합니다.
        `,
      },
    ],
    tag: ["커리어1", "커리어1", "커리어1"],
    img: `${process.env.PUBLIC_URL}/assets/images/slide0.png`,
    title_en:
      "A future where blockchain technology becomes routine | Return Plus CEO Interview1",
    content_en: [
      {
        c: `RETURN PLUS's logo is blue, symbolizing trust based on data transparency and security, and orange, symbolizing growth and energy. 
        The core concepts of our 'RETURN PLUS' are "additional value", "growth", and "scalability that can be applied to various industries such as finance and asset management."
        The curved design of the iconic plus (+) shape emphasizes flexibility.
        In this way, RETURN PLUS pursues growth and expansion based on trust and provides innovative solutions that can be flexibly applied to various industries.`,
      },
    ],
    tag_en: ["carrier1"],
  },

];

export const visionData = [
  {
    subTitle: "Mission",
    title: `블록체인 혁신으로 
고객의 경제적 가치 창출을 이끌어냅니다`,
    content: `급변하는 디지털 시대에, 우리는 블록체인 기술의 혁신을 통해 고객들이 새로운 경제적 기회를 발견하고 최대한의 가치를 실현할 수 있도록 돕습니다. 
    글로벌 금융 시장의 변화에 발맞춰, 복잡한 금융의 장벽을 허물고 누구나 쉽게 접근할 수 있는 혁신적인 금융 서비스를 제공합니다. 
    이를 통해 고객과 함께 성장하며, 미래 금융의 새로운 표준을 만들어갑니다.`,
  },
  {
    subTitle: "Vision",
    title: `미래 금융을 선도하는 글로벌 블록체인 인프라 기업`,
    content: `금융의 디지털 혁명이 가속화되는 시대에, 리턴플러스는 블록체인 기술로 금융의 미래를 선도하고자 합니다. 
    우리는 혁신적인 기술력과 글로벌 네트워크를 통해 전 세계 금융 시장을 연결하고, 투명하고 신뢰할 수 있는 금융 인프라를 구축합니다. 
    시장 변화에 적극 대응하며, 고객들에게 전례 없는 금융 경험과 기회를 제공함으로써 글로벌 리더로서의 위치를 확고히 하겠습니다.`,
  },
  {
    subTitle: "Slogan",
    title: `Unlocking the Future of Finance with Blockchain`,
    content: `리턴플러스는 블록체인 기술로 금융의 미래를 열어갑니다. 
    기존의 한계를 뛰어넘어 새로운 금융의 문을 열고, 고객들이 글로벌 시장에서 무한한 가능성을 발견할 수 있도록 지원합니다. 
    변화하는 시장에서 앞서 나가는 혁신적인 금융 솔루션으로 함께 성장합니다.`,
  },
];
