import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Container = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 430px) {
    height: 70vh;
  }
`;

const Wrapper = styled.div`
  padding: 0 15vw;
  margin: auto 0;
  @media screen and (max-width: 430px) {
    padding: 0 1rem;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  margin-bottom: 4rem;
`;

const Title = styled.span`
  font-size: 2.5rem;
  font-weight: bold;
  @media screen and (max-width: 430px) {
    font-size: 2rem;
  }
`;

const SubTitle = styled.span`
  font-size: 1.2rem;
  @media screen and (max-width: 1024px) {
    font-size: 1rem;
  }
`;

const ContentBox = styled.div``;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  text-align: center;
  padding: 3rem 3rem;
  &:first-of-type {
    padding: 3rem 3rem 3rem 0rem;
  }
  &:last-of-type {
    padding: 3rem 0rem 3rem 3rem;
  }
  @media screen and (max-width: 430px) {
    width: 33.33%;
    padding: 1rem 0rem;
    &:first-of-type {
      padding: 1rem 0;
    }
    &:last-of-type {
      padding: 1rem 0;
    }
  }
`;

const Img = styled.img`
  width: 15rem;
  height: 15rem;
  margin-bottom: 1rem;
  @media screen and (max-width: 1024px) {
    width: 10rem;
    height: 10rem;
  }

  @media screen and (max-width: 430px) {
    width: 5rem;
    height: 5rem;
  }
`;

const ItemTitle = styled.span`
  font-size: 1.6rem;
  font-weight: bold;
  @media screen and (max-width: 430px) {
    font-size: 0.9rem;
  }
`;

const ItemSubTitle = styled.span`
  line-height: 1.2;
  max-width: 15rem;
  @media screen and (max-width: 1024px) {
    max-width: 10rem;
  }
  @media screen and (max-width: 430px) {
    font-size: 0.7rem;
    max-width: 5rem;
  }
`;

const Line = styled.span`
  width: 1px;
  background-color: #c2c2c2;
`;

function Service() {
  const { t } = useTranslation();
  const blankImg = `${process.env.PUBLIC_URL}/assets/images/blank.jpg`;

  return (
    <Container>
      <Wrapper>
        <TitleBox>
          <Title>{t("RETURN PLUS")}</Title>
          <SubTitle>
            {t(
              "블록체인 기반 사업 추진 기업, 기관 대상으로 컨설팅, 맞춤형 인프라 구축."
            )}
          </SubTitle>
        </TitleBox>
        <ContentBox>
          <Content>
            <Item>
              <Img
                src={`${process.env.PUBLIC_URL}/assets/images/product1.png`}
              />
              <ItemTitle>{t("Wallet")}</ItemTitle>
              <ItemSubTitle>{t("기관, 기업을 위한 월렛")}</ItemSubTitle>
            </Item>
            <Line />
            <Item>
              <Img
                src={`${process.env.PUBLIC_URL}/assets/images/product2.png`}
              />
              <ItemTitle>{t("Platform")}</ItemTitle>
              <ItemSubTitle>
                {t("다양한 자산을 토큰화하는")} <br />
                {t("STO플랫폼")}
              </ItemSubTitle>
            </Item>
            <Line />
            <Item>
              <Img
                src={`${process.env.PUBLIC_URL}/assets/images/product3.png`}
              />
              <ItemTitle>{t("Trading")}</ItemTitle>
              <ItemSubTitle>{t("투자 트레이딩 플래폼")}</ItemSubTitle>
            </Item>
          </Content>
        </ContentBox>
      </Wrapper>
    </Container>
  );
}

export default Service;
