import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { visionData } from "../../api/staticData";

const Container = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  padding: 1rem 5rem 5rem 5rem;
  position: relative;
  background-color: #000;
  @media screen and (max-width: 1280px) {
    padding: 4rem 6rem;
    height: 100%;
  }
  @media screen and (max-width: 430px) {
    padding: 0rem 2rem;
    padding-bottom: 1rem;
    height: 100%;
  }
`;

const Item = styled.div`
  display: grid;
  grid-template-columns: 4fr 6fr;
  gap: 3rem;
  align-items: end;
  padding-bottom: 3rem;
  line-height: 1.2;
  color: #fff;
  &:nth-child(2) {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
  }
  @media screen and (max-width: 1280px) {
    grid-template-columns: 1fr;
    padding-bottom: 3rem;
  }
  @media screen and (max-width: 430px) {
    padding: 1.5rem 0 3.5rem 0;
    gap: 2rem;
  }
`;

const LeftTextBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 1.5rem;
  word-break: keep-all;
  @media screen and (max-width: 430px) {
    gap: 1rem;
  }
`;

const SubTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  @media screen and (max-width: 430px) {
    font-size: 1rem;
  }
`;

const Title = styled.div`
  font-size: 2.5rem;
  font-weight: bold;
  white-space: pre-wrap;
  @media screen and (max-width: 430px) {
    font-size: 1.8rem;
    line-height: 1.2;
  }
`;

const RightTextBox = styled.div`
  font-size: 1.2rem;
  word-break: keep-all;
  @media screen and (max-width: 430px) {
    font-size: 1rem;
    line-height: 1.4;
  }
`;

function Vision({ src, setSrc }) {
  const { t } = useTranslation();
  return (
    <Container>
      {visionData.map((data, index) => (
        <Item key={index}>
          <LeftTextBox>
            <SubTitle>{t(`visionData.${data.subTitle}.subTitle`)}</SubTitle>
            <Title>{t(`visionData.${data.subTitle}.title`)}</Title>
          </LeftTextBox>
          <RightTextBox>
            {t(`visionData.${data.subTitle}.content`)}
          </RightTextBox>
        </Item>
      ))}
    </Container>
  );
}
export default Vision;
