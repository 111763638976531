import React, { useEffect, useState } from "react";
import styled from "styled-components";
import IRDownload from "../../components/Button/IRDownload";
import { useTranslation } from "react-i18next";

const Div = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Wrapper = styled.div`
  background-color: none;
  height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BackgroudVideoBox = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid black;
  z-index: -99;
  filter: brightness(30%);
`;

const BackgroundVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Box = styled.div`
  width: 100%;
  height: 60vh;
  color: white;
  text-align: center;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  transition: opacity 3s ease-in;
`;

const VisibleBox = ({ isVisible, children }) => {
  return <Box $isVisible={isVisible}>{children}</Box>;
};

const SubjectBox = styled.div`
  font-size: 5rem;
  display: grid;
  row-gap: 2.5rem;
  font-family: "Pretendard-SemiBold";
  @media (max-width: 480px) {
    row-gap: 1.5rem;
  }
`;

const ContactButton = styled.button`
  margin-top: 1rem;
  position: relative;
  width: 9rem;
  height: 2.3rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  background: none;
  overflow: hidden;
  transition: all 0.3s;
  color: white;
  font-family: "Pretendard-Medium";
  font-size: 1.2rem;
  transition: all 0.2s linear;
  &:hover {
    color: black;
    background-color: white;
  }
  @media (max-width: 480px) {
    width: 8rem;
    height: 2rem;
    font-size: 1rem;
  }
`;

const SubjectContent = styled.p`
  background: linear-gradient(90deg, #375c9f 5%, #61add2 50%, #e184ca 95%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  @media (max-width: 480px) {
    font-size: 2rem;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    font-size: 4rem;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    font-size: 5rem;
  }
`;

const ContentBox = styled.div`
  font-size: 1.5rem;
  margin-top: 2rem;
  display: grid;
  row-gap: 0.5rem;
  padding: 0 2rem 0 2rem;
  font-family: "Pretendard-Regular";
  @media (max-width: 480px) {
    font-size: 0.7rem;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    font-size: 1rem;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    font-size: 1.3rem;
  }
`;

const ButtonBox = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  gap: 2rem;
`;

function Landing({ setIsOpen, onScrollToSection }) {
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation(); // 번역 훅

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <Div>
      <Wrapper>
        <BackgroudVideoBox>
          <BackgroundVideo playsInline autoPlay loop muted>
            <source
              src="/assets/LandingBackground.mp4"
              type="video/mp4"
            ></source>
          </BackgroundVideo>
        </BackgroudVideoBox>
        <VisibleBox isVisible={isVisible}>
          <SubjectBox>
            <SubjectContent>{t("A Leader in Global")}</SubjectContent>
            <SubjectContent>{t("Blockchain Financial")}</SubjectContent>
            <SubjectContent>{t("Infrastructure")}</SubjectContent>
          </SubjectBox>
          <ContentBox>
            <p>{t("리턴플러스는 블록체인 기술을 활용하여")}</p>
            <p>
              {t(
                "실물 자산의 토큰증권 발행과 유통을 전문으로 하는 핀테크 기업입니다."
              )}
            </p>
            <br />
            <p>
              {t(
                "'한조각' 플랫폼을 통해 부동산, 의료기기, 예술품, 하이퍼카 등"
              )}
            </p>
            <p>{t("다양한 자산을 누구나 쉽게 투자할 수 있도록 토큰화하여")}</p>
            <p>{t("새로운 투자 기회를 제공합니다.")}</p>
          </ContentBox>
          <ButtonBox>
            <ContactButton onClick={() => onScrollToSection("research")}>
              {t("컨설팅 요청")}
            </ContactButton>
            <IRDownload setIsOpen={setIsOpen} />
          </ButtonBox>
        </VisibleBox>
      </Wrapper>
    </Div>
  );
}

export default Landing;
