import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

const Container = styled.section`
  width: 100%;
  height: 100%;
  background-color: #000;
  color: #fff;
`;

const Wrapper = styled.div``;

const MaskBox = styled.div`
  position: relative;
  height: 300vh;
`;

const StickyMask = styled.div`
  display: flex;
  overflow: hidden;
  position: sticky;
  top: 0;
  height: 100vh;
  align-items: center;
  justify-content: center;
  mask-image: url("${process.env.PUBLIC_URL}/assets/images/클리핑.svg");
  mask-position: 53.55% center;
  mask-repeat: no-repeat;
  mask-size: 30%;
  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

function ClipMask() {
  const clipmaskRef = useRef(null);
  const stickyMaskRef = useRef(null);

  const easedScrollProgress = useRef(0);

  const [initialMaskSize, setInitialMaskSize] = useState(40);
  const targetMaskSize = 1;
  const easing = 0.5;

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 430) {
        setInitialMaskSize(200);
      } else if (width <= 1024) {
        setInitialMaskSize(200);
      } else {
        setInitialMaskSize(160);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getScrollProgress = useCallback(() => {
    if (!stickyMaskRef.current || !clipmaskRef.current) {
      return 0;
    }
    const scrollProgress =
      stickyMaskRef.current.offsetTop /
      (clipmaskRef.current.getBoundingClientRect().height - window.innerHeight);
    const delta = scrollProgress - easedScrollProgress.current;
    easedScrollProgress.current += delta * easing;
    return easedScrollProgress.current;
  }, [easing]);

  const animate = useCallback(() => {
    if (!stickyMaskRef.current) return;
    const maskSizeProgress =
      getScrollProgress() * (initialMaskSize - targetMaskSize);
    stickyMaskRef.current.style.webkitMaskSize =
      (initialMaskSize - maskSizeProgress) * 100 + "%";
    requestAnimationFrame(animate);
  }, [initialMaskSize, targetMaskSize, getScrollProgress]);

  useEffect(() => {
    if (stickyMaskRef.current && clipmaskRef.current) {
      requestAnimationFrame(animate);
    }
  }, [animate]);

  return (
    <Container>
      <Wrapper>
        <MaskBox ref={clipmaskRef}>
          <StickyMask ref={stickyMaskRef}>
            <video autoPlay muted loop>
              <source
                src={`${process.env.PUBLIC_URL}/assets/video/clipmask.mp4`}
                type="video/mp4"
                loop
                muted
                playsInline
              />
            </video>
          </StickyMask>
        </MaskBox>
      </Wrapper>
    </Container>
  );
}

export default ClipMask;
