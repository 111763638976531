import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Overlay = styled.div`
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 5.5rem 2rem 10rem 2rem;
  z-index: 95;
  background-color: black;
  opacity: ${(props) => (props.$naviOpen ? 1 : 0)};
  transition: opacity 0.5s ease;
  pointer-events: ${(props) => (props.$naviOpen ? "auto" : "none")};
  display: block;
  justify-content: space-between;
  @media (min-width: 1048px) {
    display: none;
  }
`;

const OverlayWrapper = styled.div`
  width: 100%;
  height: 70%;
  padding: 3.5rem 0 0 3rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @media (max-width: 768px) {
    padding: 3.5rem 0 0 0;
  }
`;

const OverlayNav = styled.button`
  background: none;
  border: none;
  color: white;
  font-family: "Pretendard-SemiBold";
  font-size: 1.5rem;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;
const OverlayContactBox = styled.div`
  width: 100%;
  height: 15%;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
`;

const Div = styled.div`
  width: 100%;
  height: 5.5rem;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.2);
  @media (max-width: 480px) {
    height: 4.5rem;
  }
`;
const Wrapper = styled.div`
  display: flex;
`;
const ImageBox = styled.div`
  width: 27rem;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 480px) {
    width: 12rem;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    width: 17rem;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    width: 25rem;
  }
`;

const LogoImage = styled.div`
  width: 15rem;
  height: 2.5rem;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  cursor: pointer;
  @media (max-width: 480px) {
    width: 9rem;
    height: 1.5rem;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    width: 13rem;
    height: 2.1rem;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    width: 14rem;
    height: 2.3rem;
  }
`;

const NaviBox = styled.div`
  width: 20rem;
  height: 3.5rem;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) {
    display: none;
  }
`;
const NaviContent = styled.button`
  background: none;
  border: none;
  font-family: "Pretendard-SemiBold";
  font-size: 1.2rem;
  color: white;
  margin: 0 auto;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;
const Hamburger = styled.label`
  display: none;
  margin-right: 2rem;
  @media (max-width: 1024px) {
    position: relative;
    width: 1.5rem;
    height: 1.2rem;
    background: transparent;
    cursor: pointer;
    display: block;
  }
`;
const HamburgerInput = styled.input`
  display: none;
  &:checked ~ span:nth-of-type(1) {
    transform: rotate(45deg);
    top: 0px;
    left: 0.1rem;
  }
  &:checked ~ span:nth-of-type(2) {
    width: 0%;
    opacity: 0;
  }
  &:checked ~ span:nth-of-type(3) {
    transform: rotate(-45deg);
    top: 1.05rem;
    left: 0.1rem;
  }
`;
const HanburgerSpan = styled.span`
  display: block;
  position: absolute;
  height: 0.25rem;
  width: 100%;
  background: white;
  border-radius: 0.563rem;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
  &:nth-of-type(1) {
    top: 0;
    transform-origin: left center;
  }
  &:nth-of-type(2) {
    top: 50%;
    transform: translateY(-50%);
    transform-origin: left center;
  }
  &:nth-of-type(3) {
    top: 100%;
    transform-origin: left center;
    transform: translateY(-100%);
  }
`;

const ContactBox = styled.div`
  width: 20rem;
  height: 3.5rem;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const LanguageButton = styled.button`
  width: 5rem;
  height: 3.5rem;
  font-family: "Pretendard-SemiBold";
  font-size: 1rem;
  border: none;
  background: none;
  cursor: pointer;
  color: white;
  transition: all 0.05s linear;
  &:hover {
    transform: scale(1.1);
  }
  @media (max-width: 480px) {
    width: 2.5rem;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    width: 3.5rem;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    width: 4.5rem;
  }
`;

const ContactButton = styled.button`
  width: 10.5rem;
  height: 3.5rem;
  border: 2px solid white;
  background: none;
  font-family: "Pretendard-SemiBold";
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s linear;
  &:hover {
    color: black;
    background-color: white;
  }
  @media (max-width: 480px) {
    width: 7.5rem;
    height: 2.5rem;
    font-size: 0.9rem;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    width: 8.5rem;
    height: 2.83rem;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    width: 9.5rem;
    height: 3.16rem;
  }
`;
function Header({ setIsOpen, onScrollToSection }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [naviOpen, setNaviOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const changeLanguage = () => {
    const newLanguage = i18n.language === "ko" ? "en" : "ko";
    i18n.changeLanguage(newLanguage);
  };

  const handleNaveSlide = () => {
    setNaviOpen(!naviOpen);
  };

  const navHandler = (section) => {
    if (pathname !== "/") {
      navigate("/", { state: { scrollTo: section } });
    } else {
      onScrollToSection(section);
    }
    setNaviOpen(false);
  };

  return (
    <>
      <Overlay $naviOpen={naviOpen}>
        <OverlayWrapper>
          <OverlayNav onClick={() => navHandler("service")}>
            {t("기업정보")}
          </OverlayNav>
          <OverlayNav onClick={() => navHandler("research")}>
            {t("문의하기")}
          </OverlayNav>
          <OverlayNav onClick={() => navHandler("newsroom")}>
            {t("뉴스룸")}
          </OverlayNav>
        </OverlayWrapper>
        <OverlayContactBox>
          <LanguageButton onClick={changeLanguage}>
            {i18n.language === "ko" ? "KR" : "EN"}
          </LanguageButton>
          <ContactButton onClick={() => setIsOpen(true)}>
            {t("IR다운로드")}
          </ContactButton>
        </OverlayContactBox>
      </Overlay>
      <Div className="SidebarBox">
        <Wrapper>
          <ImageBox>
            <LogoImage
              src="/assets/HeaderLogo.png"
              onClick={() => navHandler("landing")}
            />
          </ImageBox>
          <NaviBox>
            <NaviContent onClick={() => navHandler("service")}>
              {t("기업정보")}
            </NaviContent>
            <NaviContent onClick={() => navHandler("research")}>
              {t("문의하기")}
            </NaviContent>
            <NaviContent onClick={() => navHandler("newsroom")}>
              {t("뉴스룸")}
            </NaviContent>
          </NaviBox>
        </Wrapper>
        <ContactBox>
          <LanguageButton onClick={changeLanguage}>
            {i18n.language === "ko" ? "KR" : "EN"}
          </LanguageButton>
          <ContactButton onClick={() => setIsOpen(true)}>
            {t("IR다운로드")}
          </ContactButton>
        </ContactBox>
        <Hamburger>
          <HamburgerInput
            type="checkbox"
            checked={naviOpen}
            onChange={handleNaveSlide}
          />
          <HanburgerSpan />
          <HanburgerSpan />
          <HanburgerSpan />
        </Hamburger>
      </Div>
    </>
  );
}

export default Header;
