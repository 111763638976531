// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      기업정보: "Info",
      문의하기: "Contact",
      뉴스룸: "Newsroom",
      언론보도: "Press Release",
      IR다운로드: "IR Download",
      보도일자: "Press Date",
      원문보러가기: "Go to original News",
      리턴플러스소식: "ReturnPlus Post",
      다른리턴플러스소식: "Other ReturnPlus Post",
      // Landing 페이지
      "A Leader in Global": "A Leader in Global",
      "Blockchain Financial": "Blockchain Financial",
      Infrastructure: "Infrastructure",
      "리턴플러스는 블록체인 기술을 활용하여":
        "ReturnPlus utilizes blockchain technology",
      "실물 자산의 토큰증권 발행과 유통을 전문으로 하는 핀테크 기업입니다.":
        "A fintech company specializing in issuing and distributing tokenized securities of physical assets.",
      "'한조각' 플랫폼을 통해 부동산, 의료기기, 예술품, 하이퍼카 등":
        "Through the 'Hanjoak' platform, we tokenize assets like real estate, medical devices, artworks, and hypercars",
      "다양한 자산을 누구나 쉽게 투자할 수 있도록 토큰화하여":
        "to make investment accessible to everyone, offering new investment opportunities.",
      "새로운 투자 기회를 제공합니다.":
        "Providing new investment opportunities.",
      "컨설팅 요청": "Consulting",
      "블록체인 기반 사업 추진 기업, 기관 대상으로 컨설팅, 맞춤형 인프라 구축.":
        "Consulting and customized infrastructure for blockchain-based businesses and institutions.",
      Wallet: "Wallet",
      "기관, 기업을 위한 월렛": "Wallet for institutions and enterprises",
      Platform: "Platform",
      "다양한 자산을 토큰화하는": "Tokenizing various assets",
      STO플랫폼: "STO platform",
      Trading: "Trading",
      "투자 트레이딩 플래폼": "Investment trading platform",

      // Information1 페이지 - Detail1 번역
      "Embracing a New Era":
        "Embracing a New Era: The Boundless Potential of Blockchain in Finance",
      "블록체인 기술의 도입으로 금융의 새로운 시대가 열리고 있습니다...": `The introduction of blockchain technology has opened a new era in finance. This revolutionary technology enables programmable value creation, storage, and transfer, allowing for flexible and customized financial services. As a result, every stage of financial transactions is automated, maximizing both security and efficiency.

Notably, by minimizing the involvement of traditional intermediaries in global financial transactions, blockchain allows for free investment in various new assets. This offers new financial opportunities for companies and individuals worldwide, driving wealth creation.

Blockchain-based financial services are rapidly growing. In 2022, the global blockchain market size reached approximately $10.5 billion, and it is expected to grow to around $1.431 trillion by 2030. The decentralized finance (DeFi) market is gaining trust and interest, with the total value locked (TVL) exceeding $50 billion.

Korea has emerged as a global leader in blockchain technology and financial innovation. Government support policies and corporate technological advancements are driving growth in this field. Korean companies are actively expanding into global markets and are expected to play a key role in the future of blockchain-based transactions.

ReturnPlus is committed to leveraging blockchain technology to provide innovative financial services. We strive to help our clients seize new financial opportunities and create a better future.`,

      // Information1 페이지 - Detail2 번역
      "The unique benefits of ReturnPlus": "The unique benefits of ReturnPlus",
      "아직 존재하지 않는 수조 달러 규모의 시장을 선점하기 위해...": `Global financial institutions are already exploring blockchain-based financial services to capture a market worth trillions of dollars that does not yet exist.

Major financial institutions like J.P. Morgan, Citi, and KKR have adopted Avalanche subnets to resolve issues related to KYC, validator authorization, and jurisdictional regulations, implementing asset management, foreign exchange transactions, and fund operations on blockchain.

Central banks in leading nations have completed testing of CBDCs based on Hyperledger Besu, and the Bank of Korea has declared that CBDC is an inevitable research subject and that the digitalization of the economy is an irreversible trend.

IMF Managing Director Kristalina Georgieva emphasized that CBDCs have the potential to replace cash and promote financial inclusion.

The UK and Singapore have approved public blockchain-based STO exchanges, expanding investment opportunities in various financial products.

ReturnPlus recognizes the necessity of new blockchain-based financial infrastructure to help people access more economic benefits. 

Through the 'Hanjoak' platform, we tokenize real estate, medical devices, artworks, hypercars, and other physical assets, enabling anyone to easily invest in premium assets.

ReturnPlus is committed to providing safe and transparent financial services through innovative blockchain technology and strict regulatory compliance, leading the future of the global financial market.`,
      // Information2 페이지 번역
      "What We Do": "What We Do",
      History: "History",
      "우리는 미래 지향적 기술과 서비스로 장기적인 파트너십을 구축합니다.":
        "We build long-term partnerships with forward-thinking technology and services.",
      더보기: "More",
      숨기기: "Hide",
      기사보러가기: "Go News",
      // Dummy Data 번역
      visionData: {
        Mission: {
          subTitle: "Mission",
          title:
            "Leading Customers Economic Value Creation through Blockchain Innovation",
          content: `In the rapidly changing digital era, we help customers discover new economic opportunities and realize maximum value through blockchain innovation. 
            We break down the barriers of complex finance and provide innovative financial services that anyone can easily access. 
            We grow with our customers and set new standards for future finance.`,
        },
        Vision: {
          subTitle: "Vision",
          title:
            "A Global Blockchain Infrastructure Company Leading Future Finance",
          content: `In an era where the digital revolution of finance is accelerating, Return Plus aims to lead the future of finance through blockchain technology. 
            We connect the global financial market with innovative technology and a global network, building transparent and trustworthy financial infrastructure. 
            We actively respond to market changes, providing unprecedented financial experiences and opportunities to our customers, and firmly establishing our position as a global leader.`,
        },
        Slogan: {
          subTitle: "Slogan",
          title: "Unlocking the Future of Finance with Blockchain",
          content: `Return Plus is unlocking the future of finance through blockchain technology. 
            We overcome existing limitations, opening new doors to finance, and helping customers discover limitless possibilities in the global market. 
            We grow together with innovative financial solutions that lead the changing market.`,
        },
      },
      workDummyData: {
        Wallet: "Wallet",
        Platform: "Platform",
        Trading: "Trading",
        off1: `Opening New Financial Services 
with Blockchain`,
        on1: `Return Plus is developing innovative financial services that anyone can easily use through blockchain technology.
We simplify complex financial processes and create a transparent and secure transaction environment, opening a new era of finance.`,
        off2: `Custom Blockchain Technology
Development for Clients`,
        on2: `We research and develop private and 
public blockchain technologies to meet the diverse needs of our clients.
We provide the optimal solution to accurately implement the services that customers want.`,
        off3: "Business Innovation with Smart Contracts and Tokenization",
        on3: `We create new business models by leveraging the advantages of smart contracts and tokenization.
Through automated contract execution and the digitization of physical assets, we enhance efficiency and reliability, offering new investment opportunities to customers.`,
        off4: "Providing One-stop Service from Consulting to Operation",
        on4: `We offer End-to-End services that bundle all processes from consulting to system development and operation.
Customers can receive perfect solutions from a single window without going through complicated procedures.`,
      },
      historyDummyData: {
        year: "Year",
        activities: {
          activity: "Activity",
        },
        2024: {
          activities: [
            {
              time: "2024.10",
              activity: "Partnership with major financial institutions",
            },
            {
              time: "2024.07",
              activity: "Began development of the 'Hanjoak' platform",
            },
            {
              time: null,
              activity:
                "Patent application for integrated land information system based on map data",
            },
            {
              time: null,
              activity:
                "Patent application for digital assetization system using blockchain-based NFTs",
            },
            {
              time: null,
              activity:
                "Patent application for loan services using security tokens",
            },
            {
              time: "2024.06",
              activity: "Venture company certification",
            },
            {
              time: "2024.05",
              activity: "Return Plus Co., Ltd. established",
            },
          ],
        },
      },
      PartnershipSubtitle:
        "We build long-term partnerships with forward-thinking technology and services.",
      ResearchForm: {
        inquiryType: "Contact Type",
        name: "Name",
        company: "Company",
        industry: "Industry",
        department: "Department",
        job: "Job",
        phone: "Phone",
        email: "Email",
        emailId: "ID",
        emailSelect: "Select",
        customEmail: "Email Domain",
        customDomain: "Enter Email Domain",
        inquiryDetails: "Detailed Contact",
        privacyPolicyAndMarketing:
          "Consent to Collection and Use of Personal Information",
        allagree: "Full acceptance of the Terms of Use",
        agree1: " Consent to collection of personal information",
        agree1Last:
          "I agree that Return Plus collects and uses my personal information for the above purposes.",
        agree2: "[Optional] Consent to receive marketing information",
        submit: "Contact",
        selectOption: "Select",
        submitSuccess:
          "An Contact email has been sent. We will respond as soon as possible. Thank you",
        submitError:
          "Your contact failed. Please contact us via Chrome browser.",
        industryOptions: {
          finance: "Finance/Fintech",
          manufacturing: "Manufacturing/Construction/Energy",
          retail: "Retail/Distribution",
          software: "Software/IT",
          government: "Government/Public Institution",
          media: "Media/Content/Entertainment",
          logistics: "Logistics/Aviation",
          healthcare: "Healthcare",
          marketing: "Marketing/Advertising",
          other: "Other",
        },
        inquiryTypeOptions: {
          product: "Product Contact",
          partnership: "Partnership",
          other: "Other",
        },
      },
      Research: {
        Inquiry: "Contact",
        Subject: "Experience new finance using blockchain technology.",
        SummaryContent1:
          "Return Plus uses blockchain technology to provide specialized services",
        SummaryContent2:
          "such as issuing and distributing token securities of real assets.",
        SummaryContent3:
          "If you have any questions or requests for consultation about our service,",
        SummaryContent4:
          "please fill out the right information and we will give you a quick and accurate answer.",
        SummaryContent5: "We look forward to your valuable comments.",
        SummaryContent6: "Thank you.",
        SubjectSub:
          "The industry's top experts collaborate with you throughout the entire process of business, service development, and operations.",
        HanjokakPlatformInfo: "Platform Info",
        HanjokakPlatformSelect: "Hanjokak",
        HanjokakSubject1: "Hanjokak is a fintech startup operating an ",
        HanjokakSubject2: "STO (Security Token Offering)",
        HanjokakSubject3: "platform using blockchain technology.",
        HanjokakContent1:
          "We tokenize high-value assets (medical devices, hypercars, artworks, etc.)",
        HanjokakContent2:
          "to provide investment opportunities for small investors.",
      },
      Summary: {
        subject: "Explore Our Lineup",
        medical: "Medical",
        hyperCar: "Hypercar",
        drawing: "Art",
        medicalMain: "You can invest in high-priced medical equipment.",
        hyperCarMain: "You can invest in expensive hypercars.",
        drawingMain: "You can throw it into expensive artworks.",
        medicalSub:
          "Tokenize high-value assets (medical devices, hypercars, art) to provide investment opportunities for small investors.",
        hyperCarSub:
          "Tokenize high-value assets (medical devices, hypercars, art) to provide investment opportunities for small investors.",
        drawingSub:
          "Tokenize high-value assets (medical devices, hypercars, art) to provide investment opportunities for small investors.",
        moreInfo: "Learn More",
        contact: "Contact",
      },
      Sidebar: {
        downloadCompanyProfile: "IR Download",
        close: "Close ",
        enterDetails: "Enter the details below to access the company profile.",
        submit: "Submit",
      },
      EmailForm: {
        name: "Name",
        company: "Company",
        industry: "Industry",
        selectOption: "Select",
        industryOptions: {
          finance: "Finance/FinTech",
          construction: "Manufacturing/Construction/Energy",
          consumer: "Consumer/Retail",
          software: "Software/IT",
          government: "Government/Public Institutions",
          media: "Media/Content/Entertainment",
          logistics: "Logistics/Aviation",
          healthcare: "Medical/Healthcare",
          marketing: "Marketing/Advertising",
          other: "Other",
        },
        department: "Department",
        jobTitle: "Job Title",
        phone: "Phone",
        email: "Email",
        emailPlaceholder: "Enter email ID",
        customDomain: "Enter Your Email Domain",
        customDomainPlaceholder: "Enter Your Email domain",
        agreePrivacyPolicy: "I agree to the privacy policy.",
        agreeMarketing: "[Optional] I agree to receive marketing information.",
        downloadCompanyProfile: "IR Download",
        successMessage:
          "Your inquiry has been submitted. We will contact you soon.",
        browserError: "Unsupported browser. Please use Chrome.",
      },
      Footer: {
        privacyPolicy: "PrivacyPolicy",
        contactUs: "Contact",
        address: "(주)Return Plus / 40, Seolleung-ro 86-gil, Gangnam-gu, Seoul",
        companyInfo: "Info",
        research: "Contact",
        newsroom: "Newsroom",
        copyright: "© 2024 Return Plus Corp. All Rights Reserved.",
      },
      IRDownload: {
        buttonText: "IR Download",
      },
      PostList: {
        returnplus: {
          title: "ReturnPlus Logo Story",
          content: [
            {
              q: "What kind of company is Return Plus?",
              a: "The founding of Return Plus has a very simple yet meaningful beginning. It started with a graduate school team project that gave deep thought to markets utilizing blockchain technology. This initial idea and passion developed through several pivots (changes in business direction), and through this process, Return Plus was able to grow into what it is today.",
            },
            {
              q: "What kind of company is Return Plus?",
              a: "The founding of Return Plus has a very simple yet meaningful beginning. It started with a graduate school team project that gave deep thought to markets utilizing blockchain technology. This initial idea and passion developed through several pivots (changes in business direction), and through this process, Return Plus was able to grow into what it is today.",
            },
          ],
          tags: ["logo", "return plus"],
        },
        hanjogak: {
          title:
            "A future where blockchain technology becomes routine | Return Plus CEO Interview2",
          tags: ["carrier2"],
        },
      },
    },
  },
  ko: {
    translation: {
      기업정보: "기업정보",
      문의하기: "문의하기",
      뉴스룸: "뉴스룸",
      언론보도: "언론보도",
      IR다운로드: "IR다운로드",
      보도일자: "보도일자",
      원문보러가기: "원문 보러가기",
      리턴플러스소식: "리턴플러스 소식",
      다른리턴플러스소식: "다른 리턴플러스 소식",
      // Landing 페이지
      "A Leader in Global": "A Leader in Global",
      "Blockchain Financial": "Blockchain Financial",
      Infrastructure: "Infrastructure",
      "리턴플러스는 블록체인 기술을 활용하여":
        "리턴플러스는 블록체인 기술을 활용하여",
      "실물 자산의 토큰증권 발행과 유통을 전문으로 하는 핀테크 기업입니다.":
        "실물 자산의 토큰증권 발행과 유통을 전문으로 하는 핀테크 기업입니다.",
      "'한조각' 플랫폼을 통해 부동산, 의료기기, 예술품, 하이퍼카 등":
        "'한조각' 플랫폼을 통해 부동산, 의료기기, 예술품, 하이퍼카 등",
      "다양한 자산을 누구나 쉽게 투자할 수 있도록 토큰화하여":
        "다양한 자산을 누구나 쉽게 투자할 수 있도록 토큰화하여",
      "새로운 투자 기회를 제공합니다.": "새로운 투자 기회를 제공합니다.",
      "컨설팅 요청": "컨설팅 요청",

      // Information1 페이지 - Detail1 번역
      "Embracing a New Era":
        "Embracing a New Era: The Boundless Potential of Blockchain in Finance",
      "블록체인 기술의 도입으로 금융의 새로운 시대가 열리고 있습니다...": `블록체인 기술의 도입으로 금융의 새로운 시대가 열리고 있습니다. 이 혁신적인 기술은 가치 생성, 저장, 전송 과정을 프로그래밍하여 유연하고 맞춤화된 금융 서비스를 가능하게 합니다. 
이를 통해 금융 거래의 모든 단계가 자동화되고 안전성과 효율성이 극대화되고 있습니다.


특히, 글로벌 금융 거래에서 기존 중개기관의 개입을 최소화함으로써 다양한 새로운 자산에 자유롭게 투자할 수 있게 되었습니다. 
이는 전 세계 기업과 개인들에게 새로운 금융 거래의 기회를 제공하여 부의 창출을 이끌고 있습니다.


현재 블록체인 기반 금융 서비스는 급속히 성장하고 있으며, 2022년 글로벌 블록체인 시장 규모는 약 105억 달러로, 2030년까지 약 1조 4,318억 달러에 이를 것으로 예상됩니다. 
탈중앙화 금융(DeFi) 시장은 총 예치자산(TVL)이 약 500억 달러를 넘어서는 등 신뢰와 관심이 높아지고 있습니다.


한국은 블록체인 기술과 금융 혁신 분야에서 세계적인 리더로 부상하고 있으며, 정부의 지원 정책과 기업들의 기술 혁신이 이 분야의 성장을 촉진하고 있습니다. 
한국 기업들은 글로벌 시장으로의 진출을 적극 추진하며, 블록체인 플랫폼을 통해 거래되는 미래에 핵심적인 역할을 할 것으로 기대됩니다.


리턴플러스는 이러한 흐름에 발맞추어 블록체인 기술을 활용한 혁신적인 금융 서비스를 제공하고자합니다. 고객들이 새로운 금융 기회를 포착하고, 더 나은 미래를 만들어 갈 수 있도록 최선을 다하겠습니다.`,

      // Information1 페이지 - Detail2 번역
      "The unique benefits of ReturnPlus": "The unique benefits of ReturnPlus",
      "아직 존재하지 않는 수조 달러 규모의 시장을 선점하기 위해...": `아직 존재하지 않는 수조 달러 규모의 시장을 선점하기 위해 글로벌 금융 기관들은 이미 블록체인 기반 금융 서비스를 시도하고 있습니다. 

J.P.Morgan, City, KKR 등 대형 금융 기관들은 아발란체 서브넷을 채택하여 KYC, 검증인 허가, 관할 규제 이슈를 해결하며 자산 관리, 외환 거래, 펀드 운용을 블록체인 상에서 구현하고 있습니다.

주요국 중앙은행들은 하이퍼레저 베수 기반의 CBDC 테스트를 완료했으며, 한국은행도 CBDC가 미룰 수 없는 연구 과제이며 경제의 디지털화는 피할 수 없는 흐름이라고 밝혔습니다.

IMF 크리스탈리나 게오르기에바 총재는 CBDC가 현금을 대체할 잠재력이 있으며 금융 포용성을 지니고 있다고 강조했습니다.

영국과 싱가포르는 퍼블릭 블록체인 기반 STO 거래소 운영을 허가하여 다양한 금융 상품에 대한 투자 기회를 확대하고 있습니다.

리턴플러스는 이러한 시대의 흐름에 발맞춰 사람들이 더 많은 경제적 혜택을 누릴 수 있도록 돕는 블록체인 신금융 인프라의 필요성을 절감했습니다.

'한조각' 플랫폼은 부동산, 의료기기, 예술품, 하이퍼카 등 다양한 실물 자산을 토큰화하여 누구나 프리미엄 자산에 쉽게 투자할 수 있게 합니다.

리턴플러스는 이러한 혁신적인 블록체인 기술과 철저한 규제 준수를 통해 안전하고 투명한 금융 서비스를 제공하며, 글로벌 금융 시장의 미래를 선도하고 있습니다.`,
      "블록체인 기반 사업 추진 기업, 기관 대상으로 컨설팅, 맞춤형 인프라 구축.":
        "블록체인 기반 사업 추진 기업, 기관 대상으로 컨설팅, 맞춤형 인프라 구축.",
      Wallet: "Wallet",
      "기관, 기업을 위한 월렛": "기관, 기업을 위한 월렛",
      Platform: "Platform",
      "다양한 자산을 토큰화하는": "다양한 자산을 토큰화하는",
      STO플랫폼: "STO플랫폼",
      Trading: "Trading",
      "투자 트레이딩 플래폼": "투자 트레이딩 플래폼",
      // Information2 페이지 번역
      "What We Do": "What We Do",
      History: "History",
      "우리는 미래 지향적 기술과 서비스로 장기적인 파트너십을 구축합니다.":
        "우리는 미래 지향적 기술과 서비스로 장기적인 파트너십을 구축합니다.",
      더보기: "더보기",
      숨기기: "숨기기",
      기사보러가기: "기사 보러가기",
      // Dummy Data 번역
      visionData: {
        Mission: {
          subTitle: "Mission",
          title: "블록체인 혁신으로 고객의 경제적 가치 창출을 이끌어냅니다",
          content: `급변하는 디지털 시대에, 우리는 블록체인 기술의 혁신을 통해 고객들이 새로운 경제적 기회를 발견하고 최대한의 가치를 실현할 수 있도록 돕습니다. 
            글로벌 금융 시장의 변화에 발맞춰, 복잡한 금융의 장벽을 허물고 누구나 쉽게 접근할 수 있는 혁신적인 금융 서비스를 제공합니다. 
            이를 통해 고객과 함께 성장하며, 미래 금융의 새로운 표준을 만들어갑니다.`,
        },
        Vision: {
          subTitle: "Vision",
          title: "미래 금융을 선도하는 글로벌 블록체인 인프라 기업",
          content: `금융의 디지털 혁명이 가속화되는 시대에, 리턴플러스는 블록체인 기술로 금융의 미래를 선도하고자 합니다. 
            우리는 혁신적인 기술력과 글로벌 네트워크를 통해 전 세계 금융 시장을 연결하고, 투명하고 신뢰할 수 있는 금융 인프라를 구축합니다. 
            시장 변화에 적극 대응하며, 고객들에게 전례 없는 금융 경험과 기회를 제공함으로써 글로벌 리더로서의 위치를 확고히 하겠습니다.`,
        },
        Slogan: {
          subTitle: "Slogan",
          title: "블록체인으로 금융의 미래를 여는 리턴플러스",
          content: `리턴플러스는 블록체인 기술로 금융의 미래를 열어갑니다. 
            기존의 한계를 뛰어넘어 새로운 금융의 문을 열고, 고객들이 글로벌 시장에서 무한한 가능성을 발견할 수 있도록 지원합니다. 
            변화하는 시장에서 앞서 나가는 혁신적인 금융 솔루션으로 함께 성장합니다.`,
        },
      },
      workDummyData: {
        Wallet: "월렛",
        Platform: "플랫폼",
        Trading: "트레이딩",
        off1: `블록체인으로 여는 새로운 금융 서비스`,
        on1: `리턴플러스는 블록체인 기술을 통해 
누구나 쉽게 이용할 수 있는 혁신적인 금융 서비스를 
개발하고 있습니다.

복잡한 금융 절차를 간소화하고, 
투명하고 안전한 거래 환경을 조성하여 
새로운 금융 시대를 열어가고 있습니다.`,
        off2: `고객 맞춤형 블록체인 기술 개발`,
        on2: `고객의 다양한 요구를 충족시키기 위해 
프라이빗과 퍼블릭 블록체인 기술을 연구하고 개발합니다.

최적의 솔루션을 제공하여 
고객이 원하는 서비스를 정확하게 구현합니다.`,
        off3: `스마트 컨트랙트&토큰화로 비즈니스 혁신`,
        on3: `스마트 컨트랙트와 토큰화의 장점을 활용하여 
새로운 비즈니스 모델을 창출합니다.

자동화된 계약 실행과 실물 자산의 디지털화를 통해 
효율성과 신뢰성을 높이고, 
고객에게 새로운 투자 기회를 제공합니다.`,
        off4: `컨설팅부터 운영까지 원스톱 서비스 제공`,
        on4: `컨설팅, 시스템 개발, 운영에 이르는 전 과정을 
하나로 묶어 End-to-End 서비스를 제공합니다.

고객은 복잡한 과정을 거치지 않고도 
하나의 창구에서 완벽한 솔루션을 받을 수 있습니다.`,
      },
      historyDummyData: {
        year: "년도",
        activities: {
          activity: "활동",
        },
        2024: {
          activities: [
            {
              time: "2024.10",
              activity: "주요 금융기관과 파트너쉽 체결",
            },
            {
              time: "2024.07",
              activity: "'한조각'플랫폼 개발 착수",
            },
            {
              time: null,
              activity:
                "지도 정보에 기반한 토지 통합 정보 제공 시스템 특허 출원",
            },
            {
              time: null,
              activity:
                "블록체인 기반 NFT를 이용한 실물 자산의 디지털 자산화 시스템 특허 출원",
            },
            {
              time: null,
              activity: "증권형 토큰을 이용한 대출 서비스 특허 출원",
            },
            {
              time: "2024.06",
              activity: "벤처기업 인증 획득",
            },
            {
              time: "2024.05",
              activity: "주식회사 리턴플러스 법인 설립",
            },
          ],
        },
      },
      PartnershipSubtitle:
        "우리는 미래 지향적 기술과 서비스로 장기적인 파트너십을 구축합니다.",
      // slideDummyData 번역
      slideDummyData: {
        title1: "리턴플러스 - 신한투자증권 1",
        content1: `블록체인 신금융 인프라 기업 리턴플러스가 금융기관, 공공기관 대상 블록체인 인프라 확산을 위해 블록체인 금융 대표 기업 신한투자증권과 전략적 업무 협약을 체결했다고 10일 밝혔다.`,
        title2: "리턴플러스 - 신한투자증권 2",
        content2: `블록체인 신금융 인프라 기업 리턴플러스가 금융기관, 공공기관 대상 블록체인 인프라 확산을 위해 블록체인 금융 대표 기업 신한투자증권과 전략적 업무 협약을 체결했다고 10일 밝혔다.`,
        title3: "리턴플러스 - 신한투자증권 3",
        content3: `블록체인 신금융 인프라 기업 리턴플러스가 금융기관, 공공기관 대상 블록체인 인프라 확산을 위해 블록체인 금융 대표 기업 신한투자증권과 전략적 업무 협약을 체결했다고 10일 밝혔다.`,
      },
      ResearchForm: {
        inquiryType: "문의 유형",
        name: "이름",
        company: "회사",
        industry: "산업군",
        department: "부서",
        job: "직함",
        phone: "전화번호",
        email: "이메일",
        emailId: "아이디",
        emailSelect: "선택하세요",
        customEmail: "직접입력",
        customDomain: "직접입력",
        inquiryDetails: "상세 문의 내용",
        privacyPolicyAndMarketing: "개인정보 수집 및 이용에 대한 동의 안내",
        allagree: "전체 동의",
        agree1: " 개인정보 수집 및 이용 동의",
        agree1Last:
          "본인은 리턴플러스가 위의 목적으로 본인의 개인정보를 수집,이용하는 데 동의합니다.",
        agree2: "[선택] 뉴스레터 및 마케팅 정보 수신 동의",
        submit: "문의하기",
        selectOption: "선택하세요",
        submitSuccess:
          "문의메일발송하였습니다. 최대한 빠른 시일내에 답변드리겠습니다. 감사합니다.",
        submitError:
          "문의에 실패했습니다. 크롬브라우저로 접속하여 문의해주세요.",
        industryOptions: {
          finance: "금융/핀테크",
          manufacturing: "제조/건설/에너지",
          retail: "소비/유통",
          software: "소프트웨어/IT",
          government: "정부/공공기관",
          media: "미디어/콘텐츠/엔터테인먼트",
          logistics: "물류/항공",
          healthcare: "의료/헬스케어",
          marketing: "마케팅/광고",
          other: "기타",
        },
        inquiryTypeOptions: {
          product: "제품문의",
          partnership: "제휴",
          other: "기타",
        },
      },
      // newsDummyData 번역
      newsDummyData: {
        title1:
          "주식회사 리턴플러스, 벤처기업확인서 획득으로 혁신적인 성장 가속화",
        content1: `주식회사 리턴플러스는 최근 벤처기업확인서를 획득하며 국내 혁신기업으로서의 입지를 더욱 굳혔다.`,
        title2:
          "주식회사 리턴플러스, 벤처기업확인서 획득으로 혁신적인 성장 가속화",
        content2: `주식회사 리턴플러스는 최근 벤처기업확인서를 획득하며 국내 혁신기업으로서의 입지를 더욱 굳혔다.`,
      },
      Research: {
        Inquiry: "문의하기",
        Subject: "RETURN PLUS, 국내 유일 블록체인 이노베이션 서비스 기업",
        SummaryContent1: "저희 리턴플러스는 블록체인 기술을 활용하여 ",
        SummaryContent2:
          "실물 자산의 토큰증권 발행과 유통과 같은 전문 서비스를 제공합니다.",
        SummaryContent3: " 저희 서비스에 대한 궁금증이나 상담 요청이",
        SummaryContent4:
          "있으신 경우, 우측 정보를 기입해 주시면 신속하고 정확한 답변을 드리겠습니다.",
        SummaryContent5: "귀하의 소중한 의견을 기다립니다.",
        SummaryContent6: "감사합니다.",
        HanjokakPlatformInfo: "플랫폼 소개",
        HanjokakPlatformSelect: "한조각",
        SubjectSub:
          "업계 최고 전문가가 비즈니스,서비스 개발,운영 전 과정을 함께합니다.",
        HanjokakSubject1: "한조각은 블록체인 기술을 활용한",
        HanjokakSubject2: "STO(Security Token Offering)",
        HanjokakSubject3: "플랫폼을 운영하는 핀테크 스타트업입니다.",
        HanjokakContent1:
          "고가의 자산(의료기기, 하이퍼카, 예술품 등)을 토큰화하여",
        HanjokakContent2: "소액 투자자들에게 투자 기회를 제공합니다.",
      },
      Summary: {
        subject: "라인업 살펴보기",
        medical: "의료기기",
        hyperCar: "하이퍼카",
        drawing: "미술품",
        medicalMain: "고가의 의료기기를 투자할 수 있습니다.",
        hyperCarMain: "고가의 하이퍼카에 투자할 수 있습니다.",
        drawingMain: "고가의 예술작품에 투저헐 수 있습니다.",
        medicalSub:
          "고가의 자산(의료기기, 하이퍼카, 예술품 등)을 토큰화하여 소액 투자자들에게 투자 기회를 제공합니다.",
        hyperCarSub:
          "고가의 자산(의료기기, 하이퍼카, 예술품 등)을 토큰화하여 소액 투자자들에게 투자 기회를 제공합니다.",
        drawingSub:
          "고가의 자산(의료기기, 하이퍼카, 예술품 등)을 토큰화하여 소액 투자자들에게 투자 기회를 제공합니다.",
        moreInfo: "더 알아보기",
        contact: "상담받기",
      },
      Sidebar: {
        downloadCompanyProfile: "회사소개서 다운로드",
        close: "닫기 ",
        enterDetails: "아래 기입란을 입력하고 회사소개서를 확인하세요.",
        submit: "제출",
      },
      EmailForm: {
        name: "이름",
        company: "회사",
        industry: "산업군",
        selectOption: "선택하세요",
        industryOptions: {
          finance: "금융/핀테크",
          construction: "제조/건설/에너지",
          consumer: "소비/유통",
          software: "소프트웨어/IT",
          government: "정부/공공기관",
          media: "미디어/콘텐츠/엔터테인먼트",
          logistics: "물류/항공",
          healthcare: "의료/헬스케어",
          marketing: "마케팅/광고",
          other: "기타",
        },
        department: "부서",
        jobTitle: "직함",
        phone: "전화번호",
        email: "이메일",
        emailPlaceholder: "아이디",
        customDomain: "직접입력",
        customDomainPlaceholder: "직접입력",
        agreePrivacyPolicy: "개인정보 수집에 동의합니다.",
        agreeMarketing: "[선택] 마케팅 정보 수신에 동의합니다.",
        downloadCompanyProfile: "회사소개서 다운로드",
        successMessage: "문의가 제출되었습니다. 곧 연락드리겠습니다.",
        browserError: "지원되지 않는 브라우저입니다. Chrome을 사용해 주세요.",
      },
      Footer: {
        privacyPolicy: "개인정보처리방침",
        contactUs: "문의하기",
        address: "(주)리턴플러스 / 서울 강남구 선릉로86길 40",
        companyInfo: "기업 정보",
        research: "문의하기",
        newsroom: "뉴스룸",
        copyright: "© 2024 Return Plus Corp. All Rights Reserved.",
      },
      IRDownload: {
        buttonText: "IR 다운로드",
      },
      PostList: {
        returnplus: {
          title: "리턴플러스 로고 스토리",
          content: [
            {
              q: "RETURN PLUS: 신뢰를 바탕으로 한 혁신적 성장과 확장",
              a: `RETURN PLUS의 로고는 파란색으로 데이터의 투명성과 보안을 바탕으로 한 신뢰를, 주황색으로 성장과 에너지를 상징합니다. 
우리 'RETURN PLUS'의 핵심 개념은 "추가적인 가치", "성장", "금융, 자산 관리 등 다양한 산업에 적용될 수 있는 확장성"입니다.
이를 나타내는 상징적인 모양인 플러스(+)의 형태의 곡선 디자인은 유연성을 강조합니다.
이처럼 RETURN PLUS는 신뢰를 바탕으로 성장과 확장을 추구하며, 다양한 산업에 유연하게 적용될 수 있는 혁신적인 솔루션을 제공합니다.`,
            },
          ],
          tags: ["로고", "리턴플러스"],
        },
        hanjogak: {
          title: "블록체인 기술이 일상화된 미래 | 리턴플러스 CEO 인터뷰2",
          tags: ["커리어2"],
        },
      },
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "ko",
  fallbackLng: "ko",
  interpolation: {
    escapeValue: false,
  },
});

export const updateI18nNews = (data) => {
  const enTranslations = {};
  const koTranslations = {};

  data.forEach((news) => {
    enTranslations[`news_${news._id}_title`] = news.title_en;
    enTranslations[`news_${news._id}_content`] = news.content_en;

    koTranslations[`news_${news._id}_title`] = news.title;
    koTranslations[`news_${news._id}_content`] = news.content;
  });

  i18n.addResources("en", "translation", enTranslations);
  i18n.addResources("ko", "translation", koTranslations);
};

export const updateI18nPost = (data) => {
  const enTranslations = {};
  const koTranslations = {};

  data.forEach((post) => {
    enTranslations[`post_${post.to}_title`] = post.title_en;
    enTranslations[`post_${post.to}_tag`] = post.tag_en[0];
    enTranslations[`post_${post.to}_content`] = post.content_en;

    koTranslations[`post_${post.to}_title`] = post.title;
    koTranslations[`post_${post.to}_tag`] = post.tag[0];
    koTranslations[`post_${post.to}_content`] = post.content;
  });

  i18n.addResources("en", "translation", enTranslations);
  i18n.addResources("ko", "translation", koTranslations);
};

export default i18n;
