import { useEffect, useState } from "react";
import styled from "styled-components";
import { getNewsList } from "../../api/news";
import { Link } from "react-router-dom";
import NewsRoomSlider from "../../components/Slider/NewsRoomSlider";
import { useTranslation } from "react-i18next";
import { updateI18nNews } from "../../i18n";
import { postListData } from "../../api/staticData";

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
`;

const News = styled.div`
  padding: 10rem;
  @media screen and (max-width: 1024px) {
    padding: 6rem 6rem;
  }
  @media screen and (max-width: 430px) {
    padding: 5rem 2rem;
  }
`;

const Title = styled.div`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 4rem;
  @media screen and (max-width: 430px) {
    font-size: 1.8rem;
    margin-bottom: 2.5rem;
  }
`;

const NewsList = styled.ul`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }

  @media screen and (max-width: 430px) {
    gap: 1rem;
  }
`;

const NewsItem = styled.li`
  border-bottom: 2px solid #000;
  &:last-child {
    border-bottom: none;
  }
  @media screen and (max-width: 1024px) {
    border-bottom: none;
  }
`;

const NewsLink = styled(Link)`
  display: grid;
  grid-template-columns: 3fr 7fr;
  gap: 4rem;
  padding: 2rem 0;

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
    gap: 0.7rem;
  }
  @media screen and (max-width: 430px) {
    padding: 1rem 0;
    gap: 0.5rem;
  }
`;

const NewsImg = styled.img`
  width: 100%;
  height: 17rem;
  object-fit: cover;
  @media screen and (max-width: 430px) {
    height: 7rem;
  }
`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    gap: 2rem;
  }
  @media screen and (max-width: 430px) {
    gap: 1rem;
  }
`;

const ContentInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media screen and (max-width: 1024px) {
    gap: 0.5rem;
  }
  @media screen and (max-width: 430px) {
    gap: 0.3rem;
  }
`;

const NewsAt = styled.span`
  color: #818384;
  font-weight: bold;
  @media screen and (max-width: 1024px) {
    font-size: 1.3rem;
  }
  @media screen and (max-width: 430px) {
    font-size: 0.7rem;
  }
`;

const NewsTitle = styled.span`
  font-size: 2rem;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-height: 1.2;
  @media screen and (max-width: 1024px) {
    -webkit-line-clamp: 2;
  }

  @media screen and (max-width: 430px) {
    font-size: 1rem;
    line-height: 1.2;
  }
`;

const NewsContent = styled.span`
  font-size: 1.5rem;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 4rem;

  @media screen and (max-width: 1024px) {
    display: none;
  }

  @media screen and (max-width: 430px) {
    font-size: 1.1rem;
  }
`;

const NewsTagList = styled.div`
  font-weight: bold;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;
const NewsTag = styled.li`
  color: #818384;
  font-weight: bold;
`;

const More = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5rem;
`;
const MoreBtn = styled.button`
  padding: 0.7rem 8rem;
  font-size: 0.9rem;
  font-weight: 600;
  background-color: transparent;
  border: 1px solid #000;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

const Post = styled.div`
  padding: 10rem;
  @media screen and (max-width: 1024px) {
    padding: 6rem 6rem;
  }
  @media screen and (max-width: 430px) {
    padding: 5rem 2rem;
  }
`;

const PostList = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1.5rem;
  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 430px) {
    gap: 1rem;
  }
`;

const PostItem = styled.li`
  padding: 2.375rem 2.625rem;
  border: 1px solid #e5e5e5;
  width: 100%;
  min-width: 9.125rem;
  @media screen and (max-width: 430px) {
    padding: 0;
    border: none;
    margin-bottom: 2rem;
  }
`;

const PostLink = styled(Link)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media screen and (max-width: 430px) {
    gap: 0.4rem;
  }
`;

const PostImg = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  @media screen and (max-width: 430px) {
    height: 7rem;
  }
`;

const PostTextBox = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 430px) {
    gap: 0.3rem;
  }
`;

const PostTag = styled.span`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: #91004e;
  font-size: 0.938rem;
  @media screen and (max-width: 430px) {
    font-size: 0.7rem;
  }
`;

const PostTitle = styled.span`
  font-weight: 500;
  white-space: pre-wrap;
  word-break: keep-all;
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-height: 1.2;
  font-size: 1.25rem;
  @media screen and (max-width: 430px) {
    font-size: 1rem;
  }
`;

function NewsRoom() {
  const { t } = useTranslation();
  const [sendList, setSendList] = useState([]);
  const [newsList, setNewsList] = useState([]);
  const [sliceList, setSliceList] = useState([]);
  const [itemsToShow, setItemsToShow] = useState(3);
  const [itemsToAdd, setItemsToAdd] = useState(3);
  const [initialItems, setInitialItems] = useState(3);
  // 뉴스 데이터 fetching
  useEffect(() => {
    const fetchData = async () => {
      const newsData = await getNewsList();
      if (newsData) {
        setSendList(newsData.news);
        setNewsList(newsData.news.slice(3));
        const slideList = newsData.news.slice(0, 3);
        const slideLength = slideList.length;
        const beforeSlide = slideList[slideLength - 1];
        const afterSlide = slideList[0];
        const copiedArr = [beforeSlide, ...slideList, afterSlide];
        setSliceList(copiedArr);
        updateI18nNews(newsData.news);
      }
    };

    fetchData();
  }, []);

  // 화면 크기에 따른 itemsToShow 및 itemsToAdd 설정
  useEffect(() => {
    const getResponsiveCounts = () => {
      const width = window.innerWidth;
      if (width <= 430) {
        return { initial: 4, add: 4 };
      } else if (width <= 1024) {
        return { initial: 6, add: 6 };
      } else {
        return { initial: 3, add: 3 };
      }
    };

    const handleResize = () => {
      const { initial, add } = getResponsiveCounts();
      setInitialItems(initial);
      setItemsToAdd(add);
      setItemsToShow(initial); // 초기화
    };

    handleResize(); // 초기 설정

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // "더 보기" 버튼 클릭 시 더 많은 뉴스 표시
  const moreBtnHandler = () => {
    setItemsToShow((prev) => Math.min(prev + itemsToAdd, newsList.length));
  };

  // "감추기" 버튼 클릭 시 뉴스 개수 초기화
  const resetBtnHandler = () => {
    setItemsToShow(initialItems);
  };

  return (
    <Container>
      <NewsRoomSlider sliceList={sliceList} sendList={sendList} />
      <News>
        <Title>{t("언론보도")}</Title>
        <NewsList>
          {newsList.slice(0, itemsToShow).map((news) => (
            <NewsItem key={news._id}>
              <NewsLink
                to={`/news/${news._id}`}
                state={{
                  state: sendList,
                }}
              >
                <NewsImg
                  src={`${process.env.PUBLIC_URL}${news.image}`}
                  alt={news.title}
                />
                <ContentBox>
                  <ContentInner>
                    <NewsAt>
                      {new Date(news.date).toLocaleDateString("ko-KR")}
                    </NewsAt>
                    <NewsTitle>{t(`news_${news._id}_title`)}</NewsTitle>
                    <NewsContent>{t(`news_${news._id}_content`)}</NewsContent>
                  </ContentInner>
                  <NewsTagList>
                    {news.press}
                    {news?.tags?.slice(0, 3).map((tag, index) => (
                      <NewsTag key={index}>#{tag}</NewsTag>
                    ))}
                    {news?.tags?.length > 3 && <NewsTag>...</NewsTag>}
                  </NewsTagList>
                </ContentBox>
              </NewsLink>
            </NewsItem>
          ))}
        </NewsList>
        <More>
          {itemsToShow >=
          initialItems +
            itemsToAdd * Math.floor(newsList.length / itemsToAdd) ? (
            <MoreBtn onClick={resetBtnHandler}>{t("숨기기")}</MoreBtn>
          ) : (
            <MoreBtn onClick={moreBtnHandler}>{t("더보기")}</MoreBtn>
          )}
        </More>
      </News>
      <Post>
        <Title>{t("리턴플러스소식")}</Title>
        <PostList>
          {postListData.map((data, index) => (
            <PostItem key={index}>
              <PostLink to={`post/${data.to}`}>
                <PostImg
                  src={`${process.env.PUBLIC_URL}/assets/images/리턴소식썸네일.png`}
                />
                <PostTextBox>
                  <PostTag>#{t(`PostList.${data.to}.tags.0`)}</PostTag>
                  <PostTitle>{t(`PostList.${data.to}.title`)}</PostTitle>
                </PostTextBox>
              </PostLink>
            </PostItem>
          ))}
        </PostList>
      </Post>
    </Container>
  );
}

export default NewsRoom;
