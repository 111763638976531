import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import NewsDetailSlider from "../../components/Slider/NewsDetailSlider";
import { useTranslation } from "react-i18next";
import { updateI18nNews } from "../../i18n";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  padding: 7rem 0;
  background-color: #fff;
`;

const HeaderBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  @media screen and (max-width: 430px) {
    padding: 0 0.6rem;
    gap: 2rem;
  }
`;

const CategoryBox = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  font-size: 1.2rem;
  color: #333;
  font-weight: bold;
  @media screen and (max-width: 430px) {
    font-size: 1rem;
    gap: 1rem;
  }
`;

const GoNewsRoom = styled.span`
  display: flex;
  align-items: center;
  gap: 2rem;
  color: #b2b2b2;
  background: none;
  border: none;
  font-size: 1.2rem;
  font-weight: bold;
  @media screen and (max-width: 430px) {
    font-size: 1rem;
    gap: 1rem;
  }
`;

const Title = styled.span`
  font-size: 2rem;
  font-weight: 600;
  @media screen and (max-width: 430px) {
    font-size: 2rem;
  }
`;

const ContentBox = styled.div`
  max-width: 75rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media screen and (max-width: 1024px) {
    width: 100%;
    padding: 0 2rem;
  }
  @media screen and (max-width: 430px) {
    width: 100%;
    padding: 0;
    gap: 1rem;
  }
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 2rem;
  border-bottom: 2px solid #000;
  @media screen and (max-width: 430px) {
    padding-left: 0.6rem;
    padding-bottom: 0rem;
    border-bottom: none;
  }
`;

const InfoLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  @media screen and (max-width: 430px) {
    font-size: 0.8rem;
  }
`;

const MainContent = styled.div`
  font-size: 1.1rem;
  line-height: 1.6;
  padding: 3rem 15rem;

  @media screen and (max-width: 1024px) {
    padding: 0rem;
  }
`;

const ContentImage = styled.img`
  width: 100%;
  height: auto;
  margin: 1.2rem 0;
  object-fit: cover;
`;

const ContentText = styled.p`
  line-height: 1.6;
  margin-bottom: 1.2rem;
  word-break: keep-all;
  white-space: pre-wrap;
  @media screen and (max-width: 1024px) {
    word-break: normal;
    font-size: 1.3rem;
  }
`;

const OriginalArticle = styled.a`
  font-weight: bold;
  color: skyblue;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  @media screen and (max-width: 430px) {
    padding: 0 0.6rem;
    font-size: 1rem;
  }
`;

const PressName = styled.div`
  font-weight: bold;
  margin-top: 3rem;

  @media screen and (max-width: 430px) {
    padding: 0 0.6rem;
    font-size: 1rem;
  }
`;

const Navigation = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
`;

const NewsBtn = styled(Link)`
  text-align: start;
  padding: 1.5rem 2.5rem;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  gap: 1rem;
  background: none;
  border: none;
  text-decoration: none;
  color: #000;

  &:hover {
    background-color: #f5f5f5;
  }

  @media screen and (max-width: 430px) {
    font-size: 1.2rem;
  }
`;

const PrevNews = styled(NewsBtn)`
  border-bottom: 1px solid #e5e5e5;
`;

const NextNews = styled(NewsBtn)``;

const PostLabel = styled.span`
  display: block;
  color: #999;
`;

const PostTitle = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

function NewsDetail({ isOpen, onScrollToSection, setIsOpen }) {
  const { t } = useTranslation();
  const location = useLocation();
  const { newsId } = useParams();
  const [newsList, setNewsList] = useState([]);
  const [news, setNews] = useState(null);
  useEffect(() => {
    if (location.state && location.state.state) {
      const newsData = location.state.state;
      setNewsList(newsData);
      const currentNews = newsData.find((data) => data._id === newsId);
      setNews(currentNews);
      updateI18nNews(location.state.state);
    }
  }, [location.state, newsId]);

  const currentNewsIndex = newsList.findIndex((data) => data._id === newsId);

  const prevNews =
    currentNewsIndex > 0
      ? newsList[currentNewsIndex - 1]
      : newsList[newsList.length - 1];
  const nextNews =
    currentNewsIndex < newsList.length - 1
      ? newsList[currentNewsIndex + 1]
      : newsList[0];

  return (
    <>
      <Header onScrollToSection={onScrollToSection} setIsOpen={setIsOpen} />
      <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />
      <Container>
        <ContentBox>
          <HeaderBox>
            <CategoryBox>
              <GoNewsRoom>
                <span>{t("뉴스룸")}</span> <span>{`>`}</span>
              </GoNewsRoom>
              <span>{t("언론보도")}</span>
            </CategoryBox>
            <Title>{news ? t(`news_${news._id}_title`) : ""}</Title>
          </HeaderBox>
          <InfoRow>
            <InfoLeft>
              <span>{t("보도일자")}</span>
              <span>|</span>
              <span>
                {news?.date
                  ? new Date(news.date).toLocaleDateString("ko-KR")
                  : ""}
              </span>
            </InfoLeft>
          </InfoRow>
          <MainContent>
            {news?.image && (
              <ContentImage src={news.image} alt={`${news.title} 이미지`} />
            )}
            <ContentText>
              {news ? t(`news_${news._id}_content`) : ""}
            </ContentText>
            <OriginalArticle
              href={news?.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("원문보러가기")} →
            </OriginalArticle>
            <PressName>{news?.press}</PressName>
          </MainContent>
          <Navigation>
            {prevNews && (
              <PrevNews
                to={`/news/${prevNews._id}`}
                state={{ state: newsList }}
              >
                <PostLabel>PREV</PostLabel>
                <PostTitle>
                  {prevNews ? t(`news_${prevNews._id}_title`) : ""}
                </PostTitle>
              </PrevNews>
            )}
            {nextNews && (
              <NextNews
                to={`/news/${nextNews._id}`}
                state={{ state: newsList }}
              >
                <PostLabel>NEXT</PostLabel>
                <PostTitle>
                  {nextNews ? t(`news_${nextNews._id}_title`) : ""}
                </PostTitle>
              </NextNews>
            )}
          </Navigation>
          <NewsDetailSlider newsList={newsList} />
        </ContentBox>
      </Container>
      <Footer
        isOpen={isOpen}
        onScrollToSection={onScrollToSection}
        setIsOpen={setIsOpen}
      />
    </>
  );
}

export default NewsDetail;
