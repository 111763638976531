import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { useState, useRef } from "react";
import {} from "react-router-dom";
import Main from "./pages/Main/Main";
import NewsDetail from "./pages/News/NewsDetail";
import HanjogakAnecdote from "./pages/Post/HanjogakAnecdote";
import ReturnPlusAnecdote from "./pages/Post/ReturnPlusAnecdote";

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const sectionRef = useRef({});
  const scrollToSection = (section) => {
    if (sectionRef.current[section]) {
      sectionRef.current[section].scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Main
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                sectionRef={sectionRef}
                scrollToSection={scrollToSection}
              />
            }
          />
          <Route
            path="news/:newsId"
            element={
              <NewsDetail
                isOpen={isOpen}
                onScrollToSection={scrollToSection}
                setIsOpen={setIsOpen}
              />
            }
          />
          <Route
            path="post/hanjogak"
            element={
              <HanjogakAnecdote
                onScrollToSection={scrollToSection}
                setIsOpen={setIsOpen}
              />
            }
          />
          <Route
            path="post/returnplus"
            element={
              <ReturnPlusAnecdote
                onScrollToSection={scrollToSection}
                setIsOpen={setIsOpen}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
