import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
`;

const Img = styled.img`
  max-width: 90vw;
  max-height: 90vh;
`;

function HistoryModal({ imgSrc, setSrc }) {
  const modalHandle = () => {
    imgSrc && setSrc(null);
  };
  return (
    <Container onClick={() => modalHandle()}>
      <div>
        <Img src={`${process.env.PUBLIC_URL}${imgSrc}`} />
      </div>
    </Container>
  );
}
export default HistoryModal;